*{margin: 0;padding: 0;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}


.container-prize{max-width:767px;margin:0 auto; height: 100%;
    background-color:#121926 !important;overflow: hidden;}
.float-ryt{float:right;}
.header-text{ margin-top: 2px;font-family: Roboto-Regular; font-size: 16px; margin-right: 16px;} 
/*.css-bdjl4r{border-radius:50%; width:360px !important; height: 400px !important;}*/
.css-bdjl4r::before{border:none !important;}
.css-bdjl4r::after{border:none !important;}
/*.css-ebdd77{max-width: 767px !important;}*/
.css-1dkwqii{max-width: 767px; margin:0px auto;}
.css-1dkwqii {margin-top: 39px;;
}
.canvas{
    height: 100px !important;
    width: 100px !important;
}
.mt14{
    margin-top: 14px;
}

.css-17s6ar2{
    /* height: 300px !important;
    width: 300px !important; */
} 