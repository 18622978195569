*{margin: 0;padding: 0;}
@font-face{font-family: Lato-Semibold; src: url(Lato-Semibold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Black; src: url(Lato-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Medium; src: url(Lato-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Bold; src: url(Lato-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Light; src: url(Lato-Light.ttf);font-weight: normal;font-style: normal;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}

.menu-container{
    height: 100vh;
    background-color: #000000;
    padding: 7px 16px 0px;
}

.menu-heading{
    margin-top: 22px;
    background-color: #2196f3;
    border-radius: 8px;
}

.menu-userProfile{
    display: flex;
    align-items: center;
    padding: 16px;
}

.menu-settings{
    display: flex;
    align-items: center;
    padding: 14px 0px 14px 49px;
    border-top: 1px solid rgb(255,255,255,0.5);
}

.menu-settings span{
    font-size: 14px;
    font-family: Roboto-Regular;
    color: #ffffff;
    opacity: 0.7;
    margin-left: 10px;
    letter-spacing: 0.1px;
    line-height: 24px;
}

.profile-pic{
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-left: 9px;
}

.profile-details{
    text-align: left;
    margin-left: 8px;
}

.profile-details p:first-child{
    font-size: 18px;
    font-family: Roboto-Medium;
    color: #ffffff;
    letter-spacing: 0.14px;
    line-height: 24px;
}

.profile-details p:last-child{
    font-size: 14px;
    font-family: Roboto-Regular;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #ffffff;
    opacity: 0.7;
}

.menu-item{
    width: 100%;
    padding: 12px 16px;
    background-color: rgb(31,31,31) !important;
    border-radius: 8px;
    margin-top: 8px;
    height: 7%;
}

.menu-item img:first-child{
    float: left;
}

.menu-item span{
    float: left;
    font-size: 14px;
    font-family: Roboto-Medium;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin-left: 16px;
}

.menu-item img:last-child{
    float: right;
}

.search-container{
    background-color: #000000;
    height: 100vh;
}

.search-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 10px 16px;
    width: 100%;
    height: 9%;
    background-color: rgb(51,51,51);
}

.searchcountry {
    padding: 8px 10px 8px;
    font-size: 14px;
    font-family: Roboto-Regular;
    background: url("../images/searchCountry.svg");
    background-repeat: no-repeat;
    text-indent: 28px;
    background-color: #000000;
    height: 100%;
    background-position: 8px 50%;
    color: rgb(255,255,255,0.4);
    width: 79%;
    border: 0;
    border-radius: 100px;
  }

  .search-field span{
      color: #2196f3;
      font-family: Roboto-Medium;
      font-size: 14px;
  }

  .search-content div{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid rgb(255,255,255,0.1);
    background-color: rgb(38,38,38);
    font-size: 16px;
    font-family: Roboto-Regular;
    color: rgb(232,232,232);
  }

  .support-container{
      height: 100vh;
      background-color: #000000 !important;
  }

  .support-head{
      padding: 16px;
      background-color: rgb(255,255,255,.1);
      display: flex;
    align-items: center;
  }

  .support-head p{
      text-align: center;
      width: 100%;
      font-family: Roboto-Medium;
      font-size: 20px;
      letter-spacing: 0.14px;
      line-height: 37px;
      color: rgb(255,255,255,0.87);
  }

  .support-item{
      margin: 10px 16px 0px;
      background-color: rgb(255,255,255,.1);
      padding: 12px 16px;
      border-radius: 8px;
      width: calc(100% - 32px);
      height: 7%;
  }

  .support-item img:first-child{
    float: left;
}

.support-item span{
    float: left;
    font-size: 14px;
    font-family: Roboto-Medium;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgb(255,255,255,0.87);
    margin-left: 16px;
}

.support-item img:nth-child(3){
    float: right;
}

.support-item span:last-child{
    font-family: Roboto-Regular;
    color: #2196f3;
    float: right;
}

.support-expandedItem{
    height: 20% !important;
}

.contact-details{
    clear: both;
    margin-top: 36px;
    width: calc(100% + 32px);
    margin-left: -16px;
    border-top: 1px solid rgb(255,255,255,0.1);
}

.contact-details p{
    width: 84%;
    padding: 16px;
    font-family: Roboto-Regular;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: rgb(255,255,255,0.3);
    text-align: left;
}

.contact-details a{
    color: rgb(53,109,169);
}