*{margin: 0;padding: 0;}
@font-face{font-family: Lato-Semibold; src: url(Lato-Semibold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Black; src: url(Lato-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Medium; src: url(Lato-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Bold; src: url(Lato-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Light; src: url(Lato-Light.ttf);font-weight: normal;font-style: normal;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;background-color: #000000 !important;}

.modal {
    /* display: none; */
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    padding: 234px 40px 234px 40px;
    
}
.modal-e{
    /* display: none; */
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: rgba(36, 48, 64,0.20);
    padding: 234px 40px 234px 40px;
    
}

@media screen and (orientation:landscape)
{
    .modal {
        /* display: none; */
        position: relative;
        z-index: 99999;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
        padding: 20% 40px;
        
    }
}

.modal-content {
	 max-width: 767px;
  	 background-color: #243040;
    margin: auto;
    position: relative;
    border-radius: 4px;
    overflow: auto;
    padding:24px 24px 18px 24px;
}

.padding-modal{
    padding: 20% 40px !important;
    position: relative !important;
}

.modal-heading{overflow:hidden ;margin-bottom: 12px;color:#000000 !important}
.attn-policySuccess{font-size: 20px;color:#10b358;font-family:Roboto-Medium;float:left;}
.attn-policyError{font-size: 20px;color:red;font-family:Roboto-Medium;float:left;}
.modal-close{ 

    float: right;
    margin-top: 42px;
    /* margin-bottom: 24px; */
    font-size: 14px;
    color: #000000;
    font-family: Roboto-Medium;}
.modal-close img{width:100%;height:100%;cursor: pointer}

.apply-modal-header p{color:rgba(255,255,255,0.65);font-size:16px;font-family: Roboto-Medium;text-align: justify;}

#tick{
    width: 20px;
    height: 20px;
margin-right: 10px;
float: left;}

.popup-p{overflow:hidden;color:#ffffff;font-size:18px;font-family: Roboto-Medium;margin-top: -4px;text-align: left;}

.popup-btn{     background-color: transparent;
    color: #e2b841;
    font-family: Roboto-Medium;
    font-size: 14px;
    border: none;
    margin-right: 15px;
    text-align: center;
    width: 74px;
}
.p-lft{position: relative;
    right: -20px;}
.p-ryt{
    position: relative;
    margin-right: 0px !important;
    right: -14px;
}    

.datepicker.default{background-color: #243040 !important;}
.datepicker.default .datepicker-header{display: none;}
.datepicker.default .datepicker-scroll li{color: rgba(255,255,255,0.50) !important; font-family: Roboto-Medium !important; font-size: 18px !important;}
.datepicker.default .datepicker-wheel{    border-top: 1px solid rgba(255,255,255,0.20) !important;
    border-bottom: 1px solid rgba(255,255,255,0.20) !important;}
/* .datepicker .datepicker-navbar{display: none;}     */
.datepicker-modal{background: none !important;}

.editMsisdnArrow{
    float: left;
    margin-right: 10px;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #ffffff;
}