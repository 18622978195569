*{margin: 0;padding: 0;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}


.coupon-div{max-width:767px;margin:0 auto;
    background-color:#121926;overflow: hidden;padding: 0px 16px 101px 16px;}

.asc{overflow: hidden;}
.cancel-coupon{width:15px;height:15px;margin-top:40px;margin-bottom: 91px;float: right;}
.coupon-box{background-color: #243040;border-radius:4px;padding:40px 20px 36px 20px;text-align: center;margin-bottom: 30px;}
.c-win{width:24px;height: 24px;margin: 0px auto;text-align: center;margin-bottom: 12px;}

.game-n{font-family: Roboto-Regular;font-size: 18px;color: #ffffff;margin-bottom: 32px;}
.coupon-c{color:rgba(255,255,255,0.65);font-family: Roboto-Regular;font-size: 14px;margin-bottom: 7px;}
.coupon-v{font-family: Roboto-Regular;font-size: 21px;color: #ffffff;margin-bottom: 13px;}
.coupon-x{color:rgba(255,255,255,0.65);font-family: Roboto-Regular;font-size: 12px;margin-bottom: 38px;}
.coupon-d{color:rgba(255,255,255,0.65);font-family: Roboto-Regular;font-size: 12px;}

.cpn-file-logo{
margin-left: 10px;
}

.cpn-file-logo img{
    width: 20px; height: 20px;
}

.valid-msg-copied{font-size: 14px;color: red;text-align: center;margin-top: 10px;}
.norecord-coins{color: #fff ;

 
    font-size: 14px;
    width: 232px;
    opacity: .5;
     font-family: Roboto-Regular; margin: 0 auto;
     margin-top:18vh; 
    }
.mt17{
    margin-top: 40vh;
}     
