*{margin: 0;padding: 0;}
@font-face{font-family: Roboto-Regular;src: url(Roboto-Regular.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Medium;src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Bold;src: url(Roboto-Bold.ttf);font-weight: normal;font-style: normal;}
a {text-decoration: none;border: none;color: #ffffff;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
button{
    border: none;
    background: none;
    outline: none;
}
.logo{margin-top: 4px;text-align: center;margin-bottom: 22px;}
.logo img{width: 100px;height: 88px;}
.pingpong{width: 180px;height: 180px;margin:0 auto;margin-bottom: 21px;background-color: #fff;border-radius: 10px;}
.ads-txt{font-family: Roboto-Medium;font-size: 16px;text-align: center;color: #fff;width: 150px;
margin: 0 auto;margin-bottom: 16px;
}
.dots{width: 62px;margin:0 auto;margin-bottom: 32px;overflow: hidden;}
.dots .dot{width: 8px;height: 8px;background-color: rgb(255,255,255,.3);float: left;margin-right: 10px;border-radius: 50%;}
.dots .dot:last-child{margin-right: 0;}
.dots .active{background-color: rgb(255,255,255,.8) !important;}
.social-btns {
    width: calc(100% - 17px);
    padding: 0 32px;
    max-width: 332px;
    margin: 0 auto;
}
.social-btns button{border:none;background: none;padding: 14px 0 15px;font-family: Roboto-Medium;font-size: 16px;
text-align: center;color: #fff;max-width: 296px;width: 100%;border-radius: 100px;border:1px solid #fff;margin-bottom: 12px;}
.ggl-btn{background: url(../images/ggl.png) no-repeat 12px 50% !important;background-size:24px 24px !important;}
.fb-btn{background: url(../images/fb.png) no-repeat 12px 50% !important;background-size:24px 24px !important; padding-left: 14px !important;}
.phn-btn{border:none !important;}
.phn-btn span{color: #999999 !important;font-family: Roboto-Regular !important;}
.login-top{padding: 3px 17px 12px;}
.back{float: left;margin-top: 8px;font-family: Roboto-Medium;font-size: 14px;color: #fff;opacity: .6;cursor: pointer;}
.login-ani{width: 80px;height: 80px;border-radius: 50%;background-color: rgb(255,255,255,.1);margin: 0 auto;}
.login-msg{font-family: Roboto-Medium;font-size: 18px;color: #fff;text-align: left;padding: 0 17px;margin-bottom: 5px;}
.login-note{font-family: Roboto-Regular;font-size: 14px;color: #fff;text-align: left;padding: 0 17px;margin-bottom: 23px;
opacity: .6;
}
.login-input-section{padding: 0 0px 3px;border-bottom:1px solid rgb(255,255,255,.25);margin-bottom: 48px;width: calc(100% - 32px);
margin-left: auto;margin-right: auto;overflow: hidden;
}
.login-btn{border:none;background: none;font-family: Roboto-Medium;font-size: 16px;color: #fff;text-align: center;
	padding: 15px 0 16px;background-color: #2196f3;max-width: 296px;border-radius: 100px;width: calc(100% - 64px);
	margin-bottom: 4px;
}
.login-link{
	font-family: Roboto-Regular;color: #999999;font-size: 12px;text-align: center;padding: 0 16px;
}
.login-link span{
	font-family: Roboto-Medium !important;color: #2196f3 !important;
}
.otp-input-section{padding: 0 0px 3px;border-bottom:1px solid rgb(255,255,255,.25);width: calc(100% - 32px);
margin-left: auto;margin-right: auto;
}
.otp-input-section input{border:none;background: none;outline: none;width: 100%;text-align: left;font-family: Roboto-Regular;
	font-size: 20px;color: #ffffff;
}
.resend{
font-family: Roboto-Medium;color: #2196f3;padding-left: 17px;text-align: left;margin-top: 8px;margin-bottom: 24px;
font-size: 12px;
}

.lang-select-text{
	color: white;
    font-size: 20px;
    font-family: Roboto-Regular;
}

.lang-select{float: left;width: 86px;}
.lang-select select{    border: none;outline: none;
    background: none;font-family: Roboto-Medium;font-size: 20px;color: #fff;text-align: left;width: 100%;
		/*-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;  */  
    }
.lang-select select option{font-family: Roboto-Medium;font-size: 18px;color: #000;text-align: left;}
.lang-select select option span{float: left;}
.lang-select select option span:first-child{margin-right: 32px;}
.lang-select select option span:last-child{float: right;}
.login-input{float: left;width: calc(100% - 98px);padding-left: 0px;}
.login-input input{border:none;background:none;outline: none;width: 100%;font-family: Roboto-Regular;font-size: 20px;
text-align: left;color: #fff;}
.sub-head{width: calc(100% - 32px);padding: 15px 16px;overflow: hidden;margin-bottom: 20px;}
.sub-head span{}
.sub-head span:first-child{float: left;font-family: Roboto-Medium;font-size: 20px;text-align: left;color: #fff;}
.sub-head span:last-child{float: right;}
.terms-close img{width: 24px;height: 24px;}
.terms-container{width: calc(100% - 32px);padding: 0 16px 16px;}
.terms-top{margin-bottom: 28px;overflow: hidden;}
.terms-top .tt-l{float: left;background-color: rgb(255,255,255,.06);border-radius: 8px;
	padding: 10px 5px 5px;margin-right: 12px;
}
.terms-top .tt-l img{width: 70px;height: 61px;}
.terms-top .tt-r{float: right;width: calc(100% - 92px);text-align: left;}
.ttr-ttl{font-family:Roboto-Medium;font-size: 20px;text-align: left;line-height: 26px;color: #fff;margin-bottom: 2px;}
.ttr-content{font-family: Roboto-Regular;font-size: 14px;text-align: left;color: #fff;}
.ttr-content span{color: #2196f3 !important;text-decoration: underline;}
.terms-date{width: 100%;clear: both;margin-bottom: 23px;}
.terms-date p{font-family: Roboto-Regular;font-size: 14px;text-align: left;color: #fff;opacity: .6;line-height: 19px;}
.intro{clear: both;font-family: Roboto-Medium;font-size: 16px;text-align: left;line-height: 22px;color: #fff;
	margin-bottom: 20px;
}
.terms-para{font-family: Roboto-Regular;font-size: 14px;line-height: 22px;text-align: left;color: #fff;margin-bottom: 20px;}
.terms-para span{color: #2196f3 !important;}

.terms-list{padding-left: 16px;}
.terms-list li{font-family: Roboto-Regular;font-size: 14px;text-align: left;margin-bottom: 10px;line-height: 22px;
	color: #fff;list-style-type: disc;
}

#bbat_lottie{
    display:block;
    overflow: hidden;
    transform: translate3d(0,0,0);
    text-align: center;
    opacity: 1;
}

.refer-head{width: calc(100% - 32px);padding: 15px 16px;background: rgb(255,255,255,.1);text-align: center;
	overflow: hidden;
}
.refer-head span{}
.back-logo{float: left;}
.back-logo img{width: 24px;height: 24px;}
.refer-ttl{font-family: Roboto-Medium;font-size: 20px;color: #fff;float: left;margin-left: calc(50% - 79px)}
.refer-rule{float: right;border:1px solid #2196f3;border-radius: 100px;color: #2196f3;cursor: pointer;
	font-family: Roboto-Regular;font-size: 12px;padding: 4px 10px;
}
.refer-container{padding: 24px 16px;text-align: center;}
.refer-msg{font-family: Roboto-Medium;text-align: center;font-size: 16px;line-height: 22px;color: #fff;
	margin-bottom: 24px;
}
.refer-ani{width: 250px;height: 250px;margin: 0 auto;margin-bottom: 12px;}
.refer-note{font-family: Roboto-Medium;font-size: 14px;line-height: 22px;color: #fff;margin-bottom: 48px;text-align: center;}
.refer-btn{border:none;background: none;outline: none;background-color: #2196f3;border-radius: 100px;
	padding: 15px 44px 14px;
}
.refer-btn span{float: left;font-family: Roboto-Medium;font-size: 16px;color: #fff;line-height: 21px;}
.refer-btn span img{width: 24px;height: 24px;}

/* The Modal (background) */
			.rule-modal {
				  display: none; /* Hidden by default */
				  position: fixed; /* Stay in place */
				  z-index: 1; /* Sit on top */
				  left: 0;
				  right: 0;
				  bottom: 0;
				  width: 100%; /* Full width */
				  height: 100%; /* Full height */
				  overflow: auto; /* Enable scroll if needed */
				  background-color: rgb(0,0,0); /* Fallback color */
				  background-color: rgba(0,0,0,0.1); /* Black w/ opacity */
				  -webkit-animation-name: fadeIn; /* Fade in the background */
				  -webkit-animation-duration: 0.4s;
				  animation-name: fadeIn;
				  animation-duration: 0.4s
			}
			/* Modal Content */
			.rule-modal-content {
				  position: absolute;
				  bottom: 0;
				  left: 0;
				  right: 0;
				  background-color: #000;
				  width: 100%;
				  -webkit-animation-name: slideIn;
				  -webkit-animation-duration: 0.8s;
				  animation-name: slideIn;
				  animation-duration: 0.8s;
				 /* height: 368px;*/
				 text-align: center;
			}
			
			
			/* Add Animation */
				@-webkit-keyframes slideIn {
				  from {bottom: -300px; opacity: 0} 
				  to {bottom: 0; opacity: 1}
				}
				
				@keyframes slideIn {
				  from {bottom: -300px; opacity: 0}
				  to {bottom: 0; opacity: 1}
				}
				
				@-webkit-keyframes fadeIn {
				  from {opacity: 0} 
				  to {opacity: 1}
				}
				
				@keyframes fadeIn {
				  from {opacity: 0} 
				  to {opacity: 1}
				}
			.scroll::-webkit-scrollbar 
			{ 
				 display:none;
			}
.rule-mdl-head{padding: 15px 16px;background-color: rgb(255,255,255,.2);width: calc(100% - 32px);
overflow: hidden;border-radius: 20px 20px 0 0;}
.rule-mdl-head span:first-child{float: left;font-family: Roboto-Medium;font-size: 20px;text-align: left;color: #fff;
line-height: 26px;}
.rule-mdl-head span:last-child{float: right;width: 24px;height: 24px;}
.rule-list li{font-family: Roboto-Regular;font-size: 14px;text-align: left;color: #fff;line-height: 16px;
margin-bottom: 24px;}		
.rule-list{padding: 12px 16px 1px;background-color: rgb(255,255,255,.15)}			

.leader-head{text-align: center;padding: 15px 16px;background-color: rgb(255,255,255,.1);overflow: hidden;}
.leader-head .head{font-family: Roboto-Medium;font-size: 20px;color: #fff;line-height: 26px;float: left;
margin-left: calc(50% - 80px);}
.leader-head span img{width: 18px;
    height: 18px;
    float: left;
    margin-top: 1.5%;
}
.leaderBanner{width: 287px;
    height: 102px;
    margin: 0 auto;
    float: left;
    margin-left: calc(50% - 143px);}

.leader-tab{background-color: rgb(33,150,243,.5);width: calc(254px - 0px);border-radius: 100px;padding: 8px;
margin: 0 auto;overflow:hidden; margin-bottom: 14px;}
.leader-tab span{float: left;border-radius: 100px;font-family: Roboto-Medium;font-size: 16px;padding: 6px 0px 5px;
		color: #ffffff;text-align: center;
}
.leader-tab span:nth-child(1){width: 80px;}
.leader-tab span:nth-child(2){width: 76px;}
.leader-tab span:nth-child(3){width: 82px;}
.leader-tab .active{background-color: #2196f3;}

.leader-tbl{background-color: rgb(255,255,255,.1)}
.leader-tbl table {
  font-family: Roboto-Regular;
  border-collapse: collapse;
  width: 100%;
}
.leader-tbl table td,th{width: 33.33%;}

.leader-tbl table td {
  
  text-align: center;
  padding: 15px 0px 14px;
  font-family: Roboto-Medium;
  font-size: 14px;color: #fff;
}.leader-tbl table tr{border-bottom:1px solid rgb(255,255,255,.1);}
.leader-tbl table tr:first-child{background-color: rgb(255,255,255,.15);border-bottom: none;}
.leader-tbl table th{padding:9px 0 7px;font-family: Roboto-Regular;font-size: 12px;color: #fff;opacity: .6;text-align: center;}
.leader-tbl table th:first-child{text-align: left;padding-left: 16px;}
.leader-tbl table th:last-child{text-align: right;padding-right: 16px;}
.leader-tbl table td:first-child{text-align: left;padding-left: 16px;}
.leader-tbl table td:last-child{text-align: right;padding-right: 16px;}
#dailyboard{display: none;}
#weeklyboard{display: none;}
#monthlyboard{display: none;}

#monthlyboard table td:last-child{text-align: right;padding-right: 48px;
	background: url(../images/go.png) no-repeat calc(100% - 16px) 50% !important;background-size:24px 24px !important;
}

.m-leader-head{text-align: center;padding: 15px 16px;background-color: rgb(255,255,255,.1);overflow: hidden;}
.m-leader-head .head{font-family: Roboto-Medium;font-size: 20px;color: #fff;line-height: 26px;float: left;
margin-left: calc(50% - 36px);}
.m-leader-head span img{width: 24px;height: 24px;float: right;}



.slick-dots{
	bottom: 9px !important;
}

.slick-dots li button:before{
	color: rgba(255,255,255,0.3) !important;
	height: 8px !important;
	width: 8px !important;
}

.magic-dots.slick-dots li.slick-active button:before {
    color: #ffffff !important;
	height: 8px !important;
	width: 8px !important;
}

.slick-dots li{
	width: 12px !important;
}

.slick-slider{
	margin-bottom: 9px !important;
}