*{margin: 0;padding: 0;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}


.container-prize{max-width:767px;margin:0 auto; height: 100%;
    background-color:#121926;;overflow: hidden;}
.prize-table{margin-top: 60px;}
.table-header{padding:7px 0px;background-color: rgba(216,216,216,0.1);font-family: Roboto-Regular; font-size: 12px; color:rgb(102 102 102);}
.prize-table table{border-collapse: collapse; width: 100%;}  
.table-header td:first-child{text-align: left; padding-left: 16px; padding-top:7px; padding-bottom: 7px;}
.table-header td:nth-child(2n){text-align: right; padding-right: 16px;padding-top:7px; padding-bottom: 7px;}
.rank{padding-top:13px;padding-bottom:13px;border-bottom: 1px solid rgb(151,151,151);font-family: Roboto-Regular; font-size: 18px; color: rgb(102,102,102); text-align: left;}
.rank span img{margin-right: 9px; width: 6px; height:6px;}
.rank span:first-child{    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 16px;}
.amount{padding-top:13px;padding-bottom:13px;padding-right:16px;text-align:right;font-size: 18px;font-family: Roboto-Regular; color:rgb(0,173,238); border-bottom: 1px solid rgb(151,151,151);}