*{margin: 0;padding: 0;}
@font-face{font-family: Lato-Semibold; src: url(Lato-Semibold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Black; src: url(Lato-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Medium; src: url(Lato-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Bold; src: url(Lato-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Light; src: url(Lato-Light.ttf);font-weight: normal;font-style: normal;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}

.yee{border-bottom:1px solid rgb(0, 0, 0,0.07)}

.container-leaderboard{max-width:767px;margin:0px auto;padding:0px;background-color: #121926 !important;
    padding-top: 0px;  overflow:auto;height:100% !important}
.header-leaderboard{background-color: #121926 !important; position: fixed; top: 0; 
    left: 0; right: 0;max-width: 767px; 
    margin: 0px auto;padding-top:14px;}
.header-leaderboard img{width: 18px; height: 18px; padding-left:18.5px;margin-top: 8px;}

.content-faq{overflow: hidden;padding-top: 46px;margin-top: 13px;}
.float-lft{float: left;}


.white-ticket-play{border-top-left-radius: 20px;border-top-right-radius: 20px;
    padding-top:28px;background-color: #ffffff;position: relative;
    margin-top: -18px;}
.switch-it-top{border-bottom:1px solid rgba(0,0,0,0.1);overflow: hidden;}
.switch-it{width: 55px;margin: 0px auto;text-align: center;}
.cricket-it{font-family: Roboto-Medium;color:#9f9e9e;font-size: 14px;padding-bottom: 9.5px;float: left;}
.cricket-it:first-child{margin-right: 40px;}
.mark-it{border-bottom:4px solid #ff594b;color: #000000;font-family: Roboto-Bold;}

.how-to-play{padding:0px 16px 15px 16px}
.plus-minus{overflow: hidden;}
.plus-minus p{font-size: 16px;color: #ffffff;font-family: Roboto-Regular;margin-left: 22px;
text-align: left;}
.plus{float:left; width:9px;height:9px;margin-top: 6px;
    margin-right: 12px;}

.minus{float:left; width:10px;height:3px;margin-top: 10px;
        margin-right: 12px;}    
.plus-minus div{width:8px;height: 1px;float: left;line-height: 17px;}

.play-content{color:rgba(255,255,255,0.50);font-family: Roboto-Regular;font-size: 14px;
    margin-top: 9px;text-align: left;padding-left:22px;overflow: hidden;}
.big-it{border-bottom:1px solid rgb(0, 0, 0,0.07);padding-bottom: 16px;padding-top: 22px;}
.big-it:first-child{padding-top: 0px;}
.diff-height{line-height: 28px !important;}
.backImgCreate{height:18px;width:18px;margin-right: 22px;float: left;margin-top: 5px;}

.faq-line{border-top:1px solid rgb(255, 255, 255,0.15);padding-top:17px}