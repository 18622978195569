*{margin: 0;padding: 0;}
@font-face{font-family: Lato-Semibold; src: url(Lato-Semibold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Black; src: url(Lato-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Medium; src: url(Lato-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Bold; src: url(Lato-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Light; src: url(Lato-Light.ttf);font-weight: normal;font-style: normal;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}


.container-leaderboard{max-width:767px;margin:0px auto;padding:0px;background-color: #121926;;
    padding-top: 0px;  overflow:auto;}

.header-leaderboard{background-color: #000000; position: fixed; top: 0; 
    left: 0; right: 0;max-width: 767px; border-bottom: 1px solid rgba(255, 255,255, 0.1);
    margin: 0px auto;padding-top:13px;}
    
.mt2{margin-top: 2px;}    

.header-no-border {
    border-bottom: none !important;
}

.header-leaderboard img{width: 18px; height: 18px; padding-left:18.5px;margin-top: 6px;}
.header-text{font-family:Roboto-Medium; font-size:24px; color:#cccccc; margin-left:22px;}
.content-faq{overflow: hidden;padding-top: 46px;margin-top: 13px;}
.float-lft{float: left;}


.container{height:100%;max-width:767px;margin:0 auto; 
    text-align:left;overflow:hidden;padding-bottom:28px;padding-left:15px;padding-right:14px;padding-top: 48px;}
    
    .banner{width:100%;overflow:hidden}
    .contact{border-bottom: 1px solid;}
    .contact-heading{font-size:24px;font-family: Roboto-Regular;color:#cccccc;text-align: left;}
    .contact-content{margin-top:20px;border-radius: 4px;background-color: rgb(255, 255, 255,0.08);padding:21px 0px 20px 18px}
    
    .black{color:#666666;font-family: Roboto-Regular;font-size:14px;margin-bottom: 12px;text-align: left;}
    .blue{font-size:18px;font-family: Roboto-Regular;color:#00adee;text-align: left;}
    .blue a{font-size:18px;font-family: Roboto-Regular;color:#00adee;text-align: left;}
    
    .customer_care{font-size:18px;font-family: Roboto-Regular;color:#cccccc;margin-right:5px}

    .write-heading{font-size:24px;font-family: Roboto-Regular;color:#cccccc;text-align: left;margin-bottom:17px}
    
    .write-content{border-radius: 4px;background-color: rgb(255, 255, 255,0.08);padding:21px 0px 20px 18px}
    .write-black{font-family: Roboto-Regular;color:#666666;font-size: 14px;text-align: left;}
    
    .write-blue{font-family: Roboto-Regular;color:#00adee;font-size: 14px; margin-bottom:15px;text-align: left;}
    .write-us{margin-top:38px;background-color:#000000 ;margin-bottom: 35px;}

    .help-div{padding:31px 16px 0px 16px;border-top:1px solid rgb(255, 255, 255,0.15)}
    .write-blue a{font-family: Roboto-Regular;color:#00adee;font-size: 14px; margin-bottom:15px;text-align: left;}
    