
.container-fantasy{max-width:767px;margin:0 auto;
    background-color:#000000;overflow: hidden;padding-bottom: 62px;}

.header{margin-bottom: 19px;}

.fantasyLeague{border: none;}

.f-back {
    width: 18px;
    height: 18px;
    margin-top: 10px;
    margin-left: 5px;
    float: left;
}