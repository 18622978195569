*{margin: 0;padding: 0;}
@font-face{font-family: Lato-Semibold; src: url(Lato-Semibold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Black; src: url(Lato-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Medium; src: url(Lato-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Bold; src: url(Lato-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Light; src: url(Lato-Light.ttf);font-weight: normal;font-style: normal;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Ooredoo-Heavy; src: url( ooredoo-heavy.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;background-color: #ffffff !important;}
.container-profile{max-width:767px;margin:0 auto; height: 100%;
    background-color:#121926;overflow: hidden;padding:14px 16px 20px 16px;}
.line-it{padding-left: 19px;}
.line-it-1{border-bottom:1px solid rgb(255, 255, 255,0.07) !important;     padding: 13px 0px 10px 0px;}


.container-profile-1{max-width:767px;margin:0 auto;
        overflow: hidden;padding:14px 16px 20px 16px;}
.p-header{overflow: hidden;margin-bottom: 13px;}
.p-back{width:18px;height: 18px;float: left;}

.p-back-1{
    margin-left: 20px;
    width: 18px;
    height: 18px;
    float: left;
    margin-top: 16px;
    margin-bottom: 10px;
}

.p-edit{width:24px;height: 24px;float: right;}

.p-image{width: 80px;height: 80px;margin:0px auto;margin-bottom: 16px;}
.p-image img{width:100%;height: 100%;}

.p-msisdn{font-family: Roboto-Regular;font-size: 16px;color:#ffffff;text-align: center;margin-bottom: 38px;}
.pd-18{padding-left: 18px;}
.p-sfh{margin:0px auto;border:1px solid rgb(255, 255, 255,0.20);border-radius: 26px;
       overflow: hidden;margin-bottom: 36px;width:67%;}
.p-share{width:48%;border-right:1px solid rgb(255, 255, 255,0.20);float: left;padding:13px 0px 11px 0px;}
.p-share:last-child{border-right: 0px;}
.pp-share{width:15px;height: 18px;margin-right: 9px;margin-bottom: -3px;}
.p-share img{width:17px;height: 20px;margin-right: 9px;margin-bottom: -5px;}
.p-share p{color:#cccccc;font-family: Roboto-Regular;font-size: 14px;}
.p-share span{color:#ffffff;font-family: Roboto-Regular;font-size: 14px;}
.xc{width:20px !important;height:20px !important}

.p-winnings{background-image: linear-gradient(to right, #e60000 , #e60000);padding:23px 22px 23px 20px;
            border-radius:4px;overflow: hidden;margin-bottom: 10px;}
.p-ques{color:#ffffff;font-family: Roboto-Regular;font-size: 18px;margin-right: 10px;float: left;}
.pp{float: left;}
.pp span{color:#ffffff;font-family: Roboto-Bold;font-size: 24px;float: left;}
.p-ans{color:#ffffff;font-family: Roboto-Bold;font-size: 24px;float: left;}
.p-winnings img{float: right;width:6px;height: 13px;margin-top: 6px;}

.p-winnings-b{background-image: linear-gradient(to right, #3186f0 , #6143cf);padding:28px 22px 28px 20px;
    border-radius:4px;overflow: hidden;}
.p-winnings-b img{float: right;width:6px;height: 13px;margin-top: 6px;}

.logout-p{border-radius: 20px;background-color: #1f1f1f;  padding: 8px 0;
           font-family: Roboto-Regular;font-size: 18px;color:#666666;width:112px;margin: 0px auto
           ;border:none;margin-bottom: 20px;margin-top: 40px;}


.profileImage{border-radius: 50%;}              

.edit-pad{padding-left: 15px !important;}

.container-leaderboard{max-width:767px;margin:0px auto;padding:0px;background-color: #121926;;
    padding-top: 0px; overflow:auto; height: 100%;}
.header-leaderboard{background-color: #000000; position: fixed; top: 0; 
    left: 0; right: 0;max-width: 767px;  
    margin: 0px auto;padding-top:14px; z-index:9999;}
.header-leaderboard img{width: 18px; height: 18px; padding-left:0px !important;margin-top: 6px;}
.header-text{font-family:Roboto-Medium !important; font-size:18px !important; color:#ffffff !important; margin-left:22px;}
.content-leaderboard{overflow: hidden;padding-top: 33px;margin-top: 24px;}
.float-lft{float: left;}

.e-div{padding:15px 16px 15px 16px;background-color: #243140;overflow: hidden;
      margin-bottom: 10px; }
.e-div p{font-family: Roboto-Regular;font-size: 18px;color:#ffffff;float: left;margin-top: 8px;}
.e-profile{width:40px;height: 40px;float: left;margin-right: 21px; border-radius: 50%;object-fit: cover;}
.e-view{width:6px;height: 13px;margin-top: 12px;}
.e-relate{overflow: hidden;float: right;}
.e-msisdn{font-family: Roboto-Regular;font-size: 16px !important;color:#ffffff !important;float: left;margin-right: 21px}
.extra-p{padding-top:19px;padding-bottom: 19px;}


.edit-info{background-color: #121926;border-top-left-radius: 20px;border-top-right-radius: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;max-width: 767px;
    width:99%;margin:0px auto}
.cancel{padding:23px 16px 23px 16px;border-bottom: 1px solid rgb(0, 0, 0,0.15);overflow: hidden;}
.cancel-p{font-family: Roboto-Medium;font-size: 14px;color:#ffffff;float: left;}
.cancel-pe{font-family: Roboto-Medium;font-size: 14px;color:rgba(255, 255, 255,0.50);float: left;}
.done{font-family: Roboto-Medium;font-size: 14px;color:#e2b841;float: right;}
.done-e{font-family: Roboto-Medium;font-size: 14px;color:#e2b841;float: right;}
.eeeee{height: 280px !important;
    background-color: #243040 !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    max-width: 767px !important;
    width: 99% !important;
    margin: 0px auto !important;}
.enter-info{padding:22px 16px 22px 19px;overflow: hidden;}
.enter-info input{  
    float: left;
    background: none;
    border: none;
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #ffffff;
    scroll-margin-bottom: 2px;
   }
.ei-new input{width: 0px; margin-left: 16px;}   
.enter-info img{width:15px;height:15px;float: right;margin-top: 3px;}

.what-e{overflow: hidden;}
.max-e{font-family: Roboto-Regular;font-size: 12px;color:#666666;float: right;margin-top: 3px;}
.error-e{color:#ff3316;    font-family: Roboto-Regular;font-size: 10px;float: left;}
.error-e-new{color:#ff3316;    margin: 0px 0px 11px 19px;font-family: Roboto-Regular;font-size: 10px;float: left;}

.stats-grey{padding:13px 0px 13px 17px;background-color: #1f1f1f;border-bottom-left-radius:4px;
border-bottom-right-radius:4px;overflow: hidden;}

.stats-left{float: left;font-family: Roboto-Regular;font-size: 14px;color:#666666}
.stats-right{float: right;font-family: Roboto-Regular;font-size: 14px;color:#666666;margin-right: 16px;}

.one-stat{overflow: hidden;display:list-item;padding-bottom: 13px;border-bottom:1px solid #979797;
margin-bottom: 14px;}

.one-stat:last-child{border:none;padding-bottom:0px;margin-bottom:0px}

/*---------------------------------RADIO BUTTON-----------------------------------------------*/
.selected-radio{color: #e2b841 !important; }
.option-item{width: 100%; overflow: hidden;    padding: 19.5px 16px;}
.o-left{float: left;font-size: 16px; font-family: Roboto-Regular; color: #ffffff;}
.o-right{float: right;}
.radio-box { 
    position: relative;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;float: left;}
.radio-checkmark { width: 18px;height: 16px;position: absolute;top: 0;left: 0;
    /* background: url("../images/inactive.svg") no-repeat 50% 50% !important; */
    background: none;
    background-size: 18px 16px !important;cursor: pointer;}    		  
.radio-checkmark:after {content: "";position: absolute;display: none;}
.radio-box input:checked ~ .radio-checkmark:after {display: block;}			
.radio-box .radio-checkmark:after {width: 18px;height: 16px;position: absolute;
    top: 0;left: 0;
	 background: url("../images/active.svg") no-repeat 50% 50% !important;background-size: 18px 16px !important;
    cursor: pointer;}	
/*------------------------------------RADIO BUTTON----------------------------------------------*/

.eng-lang{
    font-size: 14px;
    margin-right: 15px;
    font-family: Roboto-Regular;
    color: rgba(255,255,255,0.5);
}

.active-lang{
    color: #2e5a86 !important;
}

.arrow-img{    margin-top: -4px !important;
    width: 24px !important;
    height: 24px  !important;}
.arrow-img img{width: 100% !important; height: 100%;}    
.mg-l-15{margin-left: 15px;}