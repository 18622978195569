* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: Lato-Semibold;
  src: url(Lato-Semibold.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Lato-Black;
  src: url(Lato-Black.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Lato-Medium;
  src: url(Lato-Medium.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Lato-Bold;
  src: url(Lato-Bold.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Lato-Light;
  src: url(Lato-Light.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto-Medium;
  src: url(Roboto-Medium.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Roboto-Regular;
  src: url(Roboto-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Ooredoo-Heavy;
  src: url(ooredoo-heavy.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Avenir-Medium;
  src: url(Avenir-Medium.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: QTZainTextBlack;
  src: url(QTZainTextBlack.otf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Avenir-Heavy;
  src: url(Avenir-Heavy.ttf);
  font-weight: normal;
  font-style: normal;
}

a {
  text-decoration: none;
  border: none;
  color: #000;
}
img {
  text-decoration: none;
  border: none;
}
ul {
  list-style: none;
}
li {
  list-style-type: none;
}
input {
  outline: none;
}

#root {
  height: 100%;
}
.App {
  height: 100%;
}
html {
  height: 100%;
}
body {
  height: 100%;
}
.container-home {
  height: 100%;
  max-width: 767px;
  margin: 0 auto;
  background-color: #121926;
  overflow: auto;
  padding-bottom: 62px;
}

/*.header{overflow: hidden;margin-bottom: 19px;}*/
.header {
  z-index: 9999;
  height: 52px;
  background-color: #121926;
  position: fixed;
  margin-bottom: 19px;
  top: 0;
  margin: 0px auto;
  width: 100%;
  max-width: 767px;
}

.header-l {
  float: left;
  margin-left: 16px;
}
.slider {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  margin-top: 14px;
  float: left;
  border-radius: 50%;
  object-fit: cover;
}
.user-name {
  font-family: Avenir-Medium;
  font-size: 12px;
  color: #ffffff;
  float: left;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 72px;
  text-align: left;
}
.games {
  width: 85px;
  height: 36px;
  padding-top: 5px;
  margin: 0px auto;
}
.games img {
  /* width: 84px;
  height: 23px;  */
}
.bell-extra {
  margin-right: 16px;
  margin-top: -21px;
  float: right;
}
.wd-28 {
  width: 28px !important;
  height: 28px !important;
}
.header-r {
  float: right;
}
/* .header-r{float: right;margin-right: 16px;margin-top: -29px;} */
.points {
  float: left;
}
.points img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  float: left;
  margin-top: -20px;
}
.bell {
  width: 18px;
  height: 23px;
  float: left;
}
.bigPlus-right {
  float: right;
  padding-right: 12px;
  margin-top: 7px;
  margin-right: 12px;
}
.bigPlus-left-img {
  width: 40px;
  /* float: left; */
  height: 40px;
}
.hhh {
  margin-left: 46px;
  margin-top: -42px;
}
.hh-1 {
  position: relative;
  font-size: 24px;
  font-family: Roboto-Medium;
  top: -2px;
}

.playwindiv-1 {
  margin-top: 4px;
  float: left;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  margin-right: 12px;
  background-color: #1a2432;
}

.playwindiv-2 {
  float: right;
}

.bigPlus-right img {
  width: 24px;
  height: 24px;
}
.bigPlus-left {
  float: left;
}
.ss-ee {
  position: relative;
  top: 2px;
}
.wd-cc {
  width: 22px !important;
  height: 22px !important;
  margin-top: 2px;
}

.content-search {
  padding: 14px 0px 0px 0px;
  margin-top: 0px;
}

.couponOk {
  display: flex;
  justify-content: flex-end;
  color: #e2b841;
  font-size: 16px;
  font-family: Roboto-Medium;
}

.wn-dt {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 30px;
  opacity: 0.65;
  font-family: Roboto-Regular;
}

.content {
  padding: 0px 0px 0px 0px;
  margin-top: 51px;
  height: 100%;
  overflow: auto;
}

.contentHome {
  padding: 0px 0px 0px 0px;
  margin-top: 65px;
}
.margintop1 {
  margin-top: 71px;
}

.banner-top {
  width: auto;
  height: 186px;
  margin-bottom: 18px;
  margin-top: 7px;
}
.message-t {
  font-family: QTZainTextBlack;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 13px;
}
.message-p {
  font-family: Avenir-Medium;
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px 0px 15px;
  text-align: center;
  margin-bottom: 14px;
}
.bluep-ee {
  position: relative;
  top: 2px;
}
.play-n {
  padding: 8px 0px 7px 0px;
  width: 106px !important;
  margin: 0px auto;
  background-color: #e2b841;
  border-radius: 4px;
  font-family: Roboto-Medium;
  font-size: 16px;
  color: #050505;
  margin-bottom: 20px;
  border: none;
}

.grey-div {
  background-color: rgba(255, 255, 255, 0.04);
  padding: 16px 16px 20px 16px;
  margin-bottom: 10px;
}
.popular {
  overflow: hidden;
  margin-bottom: 31px;
}

.yellow-h {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-left: 1px;
  float: left;
  margin-top: 4px;
}
.popular-games {
  margin-top: 6px;
  float: left;
  font-family: Roboto-Medium;
  font-size: 16px;
  color: #e2b841;
}
.view-all {
  float: right;
  margin-top: 8px;
}
.view-all p {
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #e2b841;
  float: left;
  margin-right: 2px;
}
.view-all img {
  width: 5px;
  height: 9px;
  float: left;
  margin-top: 4px;
}

.view-posters {
  overflow: auto;
  display: flex;
}
.games-d {
  float: left;
  margin-right: 11px;
}
.games-d:last-child {
  margin-right: 0px;
}
.games-d img {
  width: 102px;
  height: auto;
  margin-bottom: 10px;
}
.games-t {
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 2px;
  text-align: center;
  max-width: 106px;
}
.how-played {
  font-family: Roboto-Medium;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.addcoins-p {
  font-family: Roboto-Regular;
}

.free {
  overflow: hidden;
  /* margin-bottom: 18px; */
}
.free-h {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-left: 1px;
  float: left;
  margin-top: 4px;
}
.free-games {
  margin-top: 6px;
  float: left;
  font-family: Roboto-Medium;
  font-size: 16px;
  color: #e2b841 !important;
}

.keep-d {
  float: left;
  margin-right: 13px;
}
.keep-d:last-child {
  margin-right: 0px;
}
.keep-d img {
  border-radius: 8px;
  width: 72px;
  height: auto;
  margin-bottom: 6px;
}
.keep-t {
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  max-width: 72px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.mini-posters {
  width: 100%;
  height: auto;
  background-color: #121926;
}
.mini-posters img {
  width: 100%;
  height: auto;
}


.mobileVerificaionImg{
  width: 94% !important;
  height: auto;
  margin: auto !important;
}


.placeholdercontent{
  opacity: 0.3 !important;
}

.grey-div:last-child {
  margin-bottom: 40px;
}

.footer {
  /* z-index: 99999999999; */
  z-index: 999999999;
  padding: 10px 0px 10px 0px;
  background-color: #121926;
  position: fixed;
  width: 100%;
  height: 62px;
  bottom: 0;
  max-width: 767px;
  box-shadow: 0 1px 3px 0 rgba(211, 216, 224, 0.4) !important;
}
.footer-c {
  width: 20%;
  float: left;
  text-align: center;
}
.footer-c:last-child {
  padding-right: 0px;
}
.first-footer {
  /* width: 26px; */
  height: 24px;
}
.last-footer {
  width: 43px;
  height: 26px;
}
.second-footer {
  width: 25px;
  height: 25px;
}
.third-footer {
  width: 46px;
  height: 24px;
}

.footer-c p {
  font-size: 9.5px;
  font-family: Roboto-Medium;
  color: rgb(255, 255, 255, 0.3);
}
.highlight {
  color: #faaf40 !important;
}
.footer-mid {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;
}

.scroll-posters {
  margin-top: -3px;
  max-width: 100%;
  overflow: auto;
}

.scroll-posters::-webkit-scrollbar {
  display: none;
}

.view-posters::-webkit-scrollbar {
  display: none;
}

.scroll-playing {
  margin-top: -3px;
  max-width: 100%;
  overflow: auto;
}

.view-posters-playing {
  overflow: auto;
  width: max-content;
}

.scroll-playing::-webkit-scrollbar {
  display: none;
}

.view-posters-playing::-webkit-scrollbar {
  display: none;
}

.smallStar {
  width: 12px !important;
  height: 12px !important;
  float: none !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-bottom: 0px !important;
}

.coins-div {
  overflow: hidden;
  float: right;
}

.coins-div-new {
  float: right;
}

.overflow-auto {
  overflow: auto !important;
}
.coin-history {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
  font-family: Roboto-Regular;
  margin-bottom: 19px;
  margin-left: 19px;
}

.coin-value{
  display: flex;
  font-size: 19px;
  color: #e2b841;
  justify-content: center;
}

/*--------- MODAL-------------------------------- */
.revokeModalShow {
  display: block;
  /* display: none; */
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  /* background-color: rgb(0,0,0); */
  background-color: rgba(0, 0, 0, 0.8);
  max-width: 767px;
  margin: 0px auto;
}

.revokeModalHide {
  display: none !important;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  max-width: 767px;
  margin: 0px auto;
}

.sidenav {
  height: 100%;
  width: 280px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: auto;
  text-align: left;
  border-top-right-radius: 20px;
  overflow-x: hidden;
  transition: 0.8s;
  background-color: #1f1f1f;
}

.sidenav a {
  margin-bottom: 0px;
  text-decoration: none;
  font-size: 18px;
  color: #555454;
  display: block;
  transition: 0.5s;

  height: 70px;
}

.menu-profile {
  text-align: left;
  border-bottom: 1px solid rgb(0, 0, 0, 0.5);
  padding-top: 48px;
  padding-right: 16px;
  background-color: #1f1f1f;
  padding-left: 16px;
  overflow: hidden;
  padding-bottom: 43px;
}
.sidebar-top {
  overflow: hidden;
  margin-bottom: 20px;
}
.white-right {
  width: 22px;
  height: 23px;
  float: right;
  margin-top: 19px;
}
.menu-pname {
  font-size: 18px;
  color: #cccccc;
  font-family: Roboto-Regular;
  float: left;
  margin-top: 19px;
}
.menu-pimg {
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 10px;
}

.sidebar-bottom {
  float: left;
  margin-left: 10px;
}
.menu-pnum {
  font-size: 14px;
  color: #ffffff;
  font-family: Roboto-Medium;
  margin-bottom: 7px;
}
.menu-ppnum {
  font-size: 12px;
  color: #232323;
  font-family: Roboto-Regular;
}

.menu-tag {
  padding-left: 16px;
  padding-top: 22px;
  background-color: #1f1f1f;
}

.tag-name {
  float: left;
  font-size: 16px;
  color: #cccccc;
  font-family: Roboto-Regular;
}
.tag-logo img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.side-i {
  float: right;
  width: 6px;
  height: 13px;
  margin-top: 7px;
  padding-right: 16px;
}
.lead-logo img {
  width: 19px;
  height: 21px;
  margin-right: 15px;
}
.underline-it {
  overflow: hidden;
  border-bottom: 1px solid rgb(255, 255, 255, 0.15);
  padding-bottom: 28px !important;
}

.underline-it-1 {
  overflow: hidden;
  border-bottom: 1px solid rgb(255, 255, 255, 0.15);
  padding-bottom: 28px !important;
}

/*--------- COIN GAMES-------------------------------- */

.coin-games {
  margin-bottom: 20px;
  overflow: hidden;
}
.gamess {
  padding: 0px 0px 0px 16px;
  overflow: hidden;
}
.gamess img {
  border-radius: 8px;
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.coin-p {
  float: left;
  width: 63%;
  margin-top: 21px;
}
.p-mt {
  margin-top: 28px;
}
.coin-ppp {
  float: left;
  width: 57%;
  margin-top: 20px;
}
.coin-p-neww {
  margin-top: -3px !important;
}
.coin-g-a {
  font-family: Roboto-Regular;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 4px;
  text-align: left;
}
.coin-g-b {
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto-Regular;
  font-size: 12px;
  text-align: left;
}
.coin-amt {
  padding: 2px 0px;
  background-color: #e2b841;
  width: 100px;
  text-align: center;
  float: right;
  border-radius: 16.5px;
  margin-right: 16px;
  margin-top: 26px;
}
.coin-amt p {
  color: #121926;
  font-family: Roboto-Medium;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.for-line-new {
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding-bottom: 0px;
}

.for-line {
  height: 47px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding-bottom: 18px;
}
.fl {
  height: 34px !important;
  padding-bottom: 0px !important;
}
.btn-wid {
  width: 69px !important;
  border-radius: 4px !important;
}
.coin-g-w {
  color: #faaf40;
  font-family: Roboto-Light;
  font-size: 14px;
  margin-top: 9px;
  float: left;
}

.line-s {
  padding-bottom: 14px !important;
}
.popular-games-d {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.for-popular {
  margin-top: 12px !important;
}
.free-t-a {
  color: #cccccc !important;
  margin-top: -10px;
}
.free-t {
  margin-top: -10px;
}

.stardiv {
  margin-top: -6px;
  overflow: hidden;
  color: #29bdff;
  font-family: Roboto-Medium;
  font-size: 14px;
  margin-right: 10px;
}

.stardiv1 {
  position: relative;
  margin-top: -6px;
  /* overflow: hidden; */
  color: #29bdff;
  font-family: Roboto-Medium;
  font-size: 14px;
  /* margin-right: 10px; */
  left: -9px;
}

.coin-amt-n1 {
  padding: 0px 9px 0px 10px;
  /* background-color: #ffffff; */
  width: 69px;
  height: 40px;
  border: 1px solid;
  border-color: #e2b841;
  /* text-align: center; */
  border-radius: 5px;
  margin-right: 16px;
  margin-top: 20px;
  text-align: start;
}
.coin-amt-n1 img{
  height: 14px;
  width: 14px;
  margin: 0%;
  margin-top: 6px;
  margin-left: 2px;
  margin-right: 4px;
}
.coin-amt-n1 span {
  font-size: 19px;
  color: #e2b841;
}
.coin-amt-n1 p {
  margin: -3px;
  font-size: 10px;
  color: #e2b841;
  display: flex;
  justify-content: center;
}

.coin-amt-n {
  padding: 3px 0px;
  background-color: #e2b841;
  width: 69px;
  text-align: center;
  border-radius: 16.5px;
  margin-right: 16px;
  margin-top: 26px;
}
.coin-amt-n p {
  color: #121926;
  font-family: Roboto-Medium !important;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

coins-required-p {
  font-family: Roboto-Medium;
}

.coin-amt-n {
  border-radius: 4px !important;
}

.coin-amt-n-pay {
  padding: 2px 0px;
  background-color: #e2b841;
  width: 100px;
  text-align: center;
  border-radius: 16.5px;
  margin-right: 30px;
  margin-top: 28px;
}
.coin-amt-n-pay p {
  color: #121926;
  font-family: Roboto-Medium;
  font-size: 18px;
}

.coin-amt-n-pay {
  border-radius: 4px !important;
}

.margin-game-left {
  padding-left: 19px !important;
}
/*--------- MY WINNINGS-------------------------------- */

.games-ww {
  padding: 18px 0px 0px 16px;
}
.line-ss {
  padding-bottom: 7px !important;
}
.games-ww img {
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 13px;
}
.coin-g-aa {
  font-family: Roboto-Regular;
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
}
.coin-g-bb {
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto-Regular;
  font-size: 12px;
  text-align: left;
}

.winnings-right {
  float: right;
  margin-right: 16px;
}
.winnings-right p:first-child {
  color: #ffffff;
  font-family: Roboto-Regular;
  font-size: 14px;
  line-height: 14px;
  margin-left: 21px;
  margin-bottom: 10px;
}
.winnings-right p:last-child {
  color: #01b922;
  font-family: Roboto-Regular;
  font-size: 12px;
  margin-right: 6px;
}
.winnings-right img {
  width: 20px;
  height: 20px;
  margin-bottom: 7px;
  float: right;
  margin-right: 0px !important;
}

.winnings-div {
  margin-top: 8px;
}
.w-red {
  color: #ff3316 !important;
}
.w-grey {
  color: rgba(0, 0, 0, 0.5) !important;
}

/*--------- FREE GAMES-------------------------------- */

.free-games-div {
  margin: 0px auto;
  overflow: hidden;
  padding: 0px 16px 0px 16px;
  margin-bottom: 55px;
  width: 308px;
}
.free-div {
  float: left;
  margin-right: 7%;
}
.free-div:last-child {
  margin-right: 0px;
}
.free-div img {
  width: 60px;
  height: 60px;
  margin-bottom: 17px;
}
.free-div p {
  color: #666666;
  font-family: Roboto-Regular;
  font-size: 14px;
}
.free-games {
  color: white;
}

/*--------- PAID GAMES-------------------------------- */

.content-paidGames {
  margin-top: 43px;
  padding: 0px 16px 0px 16px;
  overflow: hidden;
}

.content-free {
  padding: 0px 16px 0px 16px;
  overflow: hidden;
}

.free-back-img {
  margin-top: 6px !important;
  margin-left: 18px !important;
}

.paidGames-lft {
  margin-bottom: 51px;
  width: calc(49% - 7px);
  font-size: 16px;
  font-family: Roboto-Regular;
  color: #ffffff;
  float: left;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  background-image: linear-gradient(
    to right,
    rgb(248, 93, 0),
    rgb(238, 129, 0)
  );
  padding: 5px 0px 5px 7px;
}
.paidGames-ryt {
  font-size: 16px;
  font-family: Roboto-Regular;
  color: #ffffff;
  float: right;
  width: calc(50% - 7px);
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  background-image: linear-gradient(
    to right,
    rgb(49, 134, 240),
    rgb(97, 67, 207)
  );
  padding: 5px 7px 5px 0px;
  margin-bottom: 51px;
}
.i-sub-lft {
  float: left;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  position: relative;
}
.i-sub-lft svg {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 76px !important;
  height: 76px !important;
}
.i-sub-ryt {
  margin-top: 18px;
  float: right;
  margin-right: 10px;
  position: relative;
}

.w-sub-lft {
  margin-top: 18px;
  float: left;
  margin-left: 10px;
}
.w-sub-ryt {
  float: right;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  position: relative;
}
.w-sub-ryt svg {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 76px !important;
  height: 76px !important;
}
.i-sub-lft img {
  width: 30px;
  height: 37px;
  margin-top: 12px;
}
.w-sub-ryt img {
  width: 30px;
  height: 37px;
  margin-top: 12px;
}
.trending {
  float: left;
  font-family: Roboto-Regular;
  font-size: 18px;
  color: rgb(251, 188, 93);
  overflow: hidden;
  margin-bottom: 17px;
}
.trending-span {
  margin-right: 10px;
}
.t-games-span {
  position: relative;
  top: -5px;
}
.unactive-p {
  color: #6c7a94 !important;
}
.active-p {
  color: #e2b841 !important;
}

.carousel-dots li button:before {
  color: #848484;
}
.carousel-dots li {
  width: 8px !important;
  height: 8px !important;
}
.carousel-dots {
  margin-bottom: 24px !important;
  margin-top: 38px !important;
  margin-left: -7px !important;
}
.carousel-dots li.carousel-dots-active button:before {
  opacity: 1 !important;
  color: rgb(11, 10, 10, 0.5) !important;
}

iframe #main_container {
  overflow: auto;
}
.carousel-initialized {
  height: 0px !important;
}

.mywinnings {
  background-color: rgb(36, 48, 64);
  height: 35px;
}

.winnings-header {
  padding: 9px 0px 9px 16px;
  opacity: 0.65;
}
/*.Coin-games:last-child .gamess .for-line{border-bottom: none !important;} */

.header-text-game {
  font-family: Roboto-Medium;
  font-size: 24px;
  color: #000000;
  margin-left: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 73%;
  text-align: left;
  white-space: nowrap;
}

.redirecting1 {
  margin-top: 184px;
  font-family: Roboto-Medium !important;
  font-size: 25px !important;
  color: #000000 !important;
  margin-left: 22px;
}

.redirecting2 {
  margin-top: 30px;
  font-family: Roboto-Regular !important;
  font-size: 16px !important;
  color: #000000 !important;
  margin-left: 22px;
}

.header-text-free {
  margin-top: 3px;
  font-family: Roboto-Medium !important;
  font-size: 18px !important;
  color: #ffffff !important;
  margin-left: 22px;
}

.h1-white {
  color: white;
}

.fa-arrow-left {
  visibility: hidden !important;
}

.fa-arrow-right {
  visibility: hidden !important;
}

.react-3d-carousel {
  height: 344px !important;
  margin-left: -5% !important;
  /* margin-left: -9% !important; */
  /* width: 420px !important; */
}
.flickity-enabled.is-draggable {
  margin-bottom: 33px !important;
}

.header-text-game {
  color: #ffffff;
}

.div-color-green {
  background-color: green;
  margin-top: -4px;
  width: 269px;
}

.div-color-pink {
  background-color: purple;
  margin-top: -4px;
  width: 269px;
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.active
  .slider-left,
.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.active
  .slider-right {
}

.react-3d-carousel .slider-container .slider-right div {
  visibility: hidden !important;
}

.react-3d-carousel .slider-container .slider-left div {
  visibility: hidden !important;
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single
  .slider-single-content {
  box-shadow: none !important;
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.active
  .slider-single-content {
  padding-top: 4px;
  /* width:273px; */
  width: 100%;
  border-radius: 8px;
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.preactive
  .slider-single-content {
  /* margin-left: -187px; */
  margin-left: -73%;
}

.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single.proactive
  .slider-single-content {
  /* margin-left: 194px; */
  margin-left: 73%;
}

.react-3d-carousel .slider-container .slider-content .slider-single {
  width: 110% !important;
}

.pg-m-extra {
  margin-top: 2px !important;
}
.g-pd-extra {
  padding-top: 19px !important;
}

.gallery-cell {
  width: 66%;
  /* height: 200px; */
  margin-right: 10px;
  /* background: #8C8; */
  /* counter-increment: gallery-cell; */
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flickity-enabled:focus .flickity-viewport {
  height: 370px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.carousel-cell .is-selected {
  height: 370px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* .div-color-red{
    width: 269px;
    margin-left: 4% !important;
    margin-right: 4% !important;
    height: 349px !important;
    border-radius: 8px !important;
}

  .div-color-red .is-selected{
    height: 370px !important;
    margin-top: 0px !important;
    margin-bottom:0px !important;    
} */

.div-color-red.is-selected {
  height: 370px;
  margin-top: 0%;
}

.div-color-red {
  /* margin-top: -4px; */
  /* width: 269px; */
  /* width: 100%; */
  /* height: 340px !important; */
  /* border-radius: 8px !important; */
  /* padding-bottom: 24px; */
  width: 78%;
  margin-top: 5.5%;
  margin-left: 2% !important;
  margin-right: 2% !important;
  height: 323px;
  border-radius: 8px !important;
  

  /* transition: all 0.5s ease; */
}

/* cell number */
.gallery-cell:before {
  display: block;

  /* text-align: center; */
  /* content: counter(gallery-cell); */
  /* line-height: 200px; */
  /* font-size: 80px; */
  /* color: white; */
}

.header-leaderboard-1 {
  height: 53px;
}
.header-leaderboard-c {
  background-color: #121926;
  display: flex;
  justify-content: space-evenly;
  max-width: 767px;
  margin: 0px auto;
  padding-top: 14px;
  align-items: center;
  height: 50px;
}

.margin-top-footer {
  margin-top: 1px !important;
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 370px !important;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
  display: none !important;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -20px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: #e2b841;
}

/*-----Redemption Center------------*/

.sub-card-expired {
  opacity: 0.5;
  margin-left: 16px;
  border-radius: 16px;
  background-color: #243040;
  float: left;
  margin-bottom: 16px;
  padding: 12px;
  width: calc(50% - 26px);
  height: calc(156px - 9px);
}

.sub-card {
  margin-top: 6px;

  margin-left: 16px;
  border-radius: 16px;
  background-color: #243040;
  float: left;
  margin-bottom: 9px;
  padding: 12px 10px 10px 10px;
  width: calc(50.5% - 26px);
  height: 156px;
}
.sc-head {
  display: flex;
  overflow: hidden;
}

.sc-left-expired {
  text-align: left;
  float: left;
  width: 66%;
}
.sc-left-expired p:first-child {
  font-size: 14px;
  font-family: Roboto-Bold;
  color: rgba(255, 255, 255, 0.3);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  margin-bottom: 6px;
}
.sc-left-expired p:nth-child(2) {
  font-size: 14px;
  font-family: Roboto-Regular;
  color: rgba(255, 255, 255, 0.3);
  line-height: 16px;
  height: 32px;
  margin-bottom: 30px;
}

.sc-left {
  float: left;
  width: 66%;
}
.sc-left p:first-child {
  margin-top: 2px;
  text-align: left;
  font-size: 14px;
  font-family: Roboto-Bold;
  color: #ffffff;
  white-space: nowrap;
  line-height: 16px;
  margin-bottom: 3px;

}
.sc-left p:nth-child(2) {
  height: 21px !important;
  text-align: left;
  font-size: 14px;
  font-family: Roboto-Regular;
  color: rgba(255, 255, 255, 0.65);

  margin-bottom: 30px;
}

.mw-sc-left {
  float: left;
}
.mw-sc-left p:first-child {
  font-size: 12px;
  font-family: Roboto-Regular;
  color: rgba(255, 255, 255, 0.9);
  line-height: 14px;
  text-transform: capitalize;
}
.mw-sc-left p:nth-child(2) {
  font-size: 20px;
  font-family: Roboto-Regular;
  color: rgba(255, 255, 255, 0.9);
  line-height: 24px;
}
.sc-right {
  float: right;
  width: 38%;
}
.sc-right img {
  width: 100%;
  height: auto;
  float: right;
  max-width: 50px;
}
.grayInfoIcon {
  opacity: 0.3;
}
.sc-bottom {
  margin-top: 27px;
  float: left;
  overflow: hidden;
}
.sc-b1 {
  margin-top: 23px !important;
}
.sc-bottom span {
  float: left;
}
.sc-bottom span:first-child img {
  width: 16px;
  height: 16px;
  float: left;
}
.sc-bottom span:nth-child(2) {
  margin-left: 6px;
  font-family: Roboto-Medium;
  font-size: 12px;
  color: #ffffff;
  float: left;
}

.sc-bottom-expired {
  margin-top: 26px;
  float: left;
  overflow: hidden;
}
.sc-bottom-expired span {
  float: left;
}
.sc-bottom-expired span:first-child img {
  width: 18px;
  height: 18px;
  float: left;
}
.sc-bottom-expired span:nth-child(2) {
  margin-left: 6px;
  font-family: Roboto-Medium;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
  float: left;
}
.expire {
  color: #ed303b;
  font-size: 10px;
  font-family: Roboto-Medium;
}
.expire-gray {
  color: rgba(255, 255, 255, 0.3);
  font-size: 10px;
  font-family: Roboto-Medium;
}

.info-div {
  float: left;
  margin-top: 4px;
  clear: both;
  width: 100%;
}
.info-div span {
  float: left;
}
.info-div span img {
  float: left;
  margin-top: 4px;
  width: 15px;
  height: 15px;
  margin-left: 1px;
}

.info-span {
  margin-top: 2px;
}

.info-div span:nth-child(2) {
  margin-left: 4px;
  font-size: 16px;
  color: #ffffff;
  font-family: Roboto-Medium;
  line-height: 19px;
}

.info-div-expired {
  float: left;
  margin-top: 4px;
  clear: both;
  width: 100%;
}
.info-div-expired span {
  float: left;
}
.info-div-expired span img {
  margin-left: 2px;
  float: left;
  margin-top: 3px;
  width: 14px;
  height: 14px;
}

.info-div-expired span:nth-child(2) {
  margin-left: 6px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.3);
  font-family: Roboto-Medium;
  line-height: 19px;
}

.mw-info-div {
  float: left;
}
.mw-info-div span {
  float: left;
}
.mw-info-div span img {
  width: 12px;
  height: 12px;
  float: left;
}
.mw-info-div span:nth-child(2) {
  margin-left: 5px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto-Regular;
  line-height: 12px;
  float: left;
  width: calc(100% - 17px);
}

.content-rules {
  text-align: left;
  font-size: 14px;
  padding: 13px 16px 20px 16px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto-Regular;
  margin-top: 60px;
  word-break: break-word;
}

.mw-coupon {
  margin-top: 20px;
  overflow: hidden;
  float: left;
  width: 100%;
}
.mw-coupon span {
  float: left;
}
.mw-coupon span:first-child {
  color: #ffffff;
  font-size: 20px;
  font-family: Roboto-Regular;
  opacity: 0.9;
  margin-right: 9px;
  line-height: 24px;
  /* width: 67%; */
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 88px; */
}
.mw-coupon span img {
  float: left;
  width: 14px;
  height: 14px;
  margin-top: 5px;
}

.mw-validity {
  width: 100%;
  float: left;
  margin-top: 17px;
}
.mw-validity span {
  float: left;
  font-style: Roboto-Regular;
  color: #ffffff;
  opacity: 0.5;
  font-size: 12px;
  line-height: 14px;
}
.mw-validity span:first-child {
  margin-right: 3px;
}
.mw-validity .ex-span {
  color: #eb1e27;
  opacity: 1 !important;
}
.mw-date {
  text-align: left;
  font-family: Roboto-Regular;
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  line-height: 14px;
  margin-left: 16px;
  margin-bottom: 12px;
}

.coupon-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 99999;
  overflow: auto;
  /* padding-bottom: 100px; */
  min-height: 500px;
}

.searchcoupon:focus {
  /* background-image:none; */
}

.searchcouponheading {
  background-color: rgba(18, 25, 38, 0.65);
  color: #ffffff;
  width: 77%;
  margin-top: 5px;
  border: 0;
  height: 100%;
  font-size: 16px;
}

.no-record-coins {
  font-family: Roboto-Medium;
  color: #ffffff;
  font-size: 16px;
  margin-top: 117px;
}

.sorry {
  font-family: Roboto-Regular;
  color: rgba(255, 255, 255, 0.65);

  margin-top: 17px;
  font-size: 12px;
}

.state-et {
  width: 232px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30vh;
}

.searchcoupon {
  padding: 8px 10px 8px;
  margin-left: 19px;
  margin-right: 16px;
  font-size: 14px;
  background: url("../images/searchCoupon.svg");
  background-repeat: no-repeat;
  text-indent: 31px;
  background-color: #243040;
  height: 38px;
  background-position: 10px 8px;
  color: #ffffff;
  width: 90%;
  margin-top: 3px;
  margin-bottom: 9px;
  border: 0;
  border-radius: 18.5px;
}

.view-coupon-body {
  padding: 148px 16px 0;
  width: calc(100% - 32px);
  font-family: Roboto-Regular;
}
.view-coupon-ticket {
  width: calc(100% - -32px);
  text-align: center;
  padding: 28px 20px 19px;
  margin-bottom: 24px;
  background-color: #243040;
  border-radius: 16px;
}
.loss-view-coupon-ticket {
  padding-bottom: 0 !important;
  padding-top: 40px !important;
}
.rnk {
  /* background-color: #eb1e27; */
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 11px;
  text-align: center;
  font-family: Roboto-Medium;
  color: #000;
  border-radius: 50%;
}
.rnk img {
  width: 50px;
  height: 50px;
}
.rnk span {
  display: block;
  font-family: Roboto-Medium;
}
.rnk-num {
  font-size: 24px;
  line-height: 28px;
  margin-top: 3px;
}
.rnk-txt {
  font-size: 12px;
  line-height: 0px;
  margin-top: 3px;
}

.wn-typ {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 7px;
  opacity: 1;
  line-height: 16px;
  font-family: Roboto-Medium;
}
.cpn-des {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 30px;
  opacity: 1;
  font-family: Roboto-Medium;
}

.cpn-ttl {
  font-family: Roboto-Regular;
  color: rgba(255, 255, 255, 0.9);
}

.cc-popup {
  font-family: Roboto-Regular;
  color: rgba(255, 255, 255, 0.9);
  line-height: 24px;
  opacity: 0.9;
  font-size: 16px;
}
.cc-pay-btn {
  border: none;
  background: none;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  background-color: #e2b841;
  border-radius: 8px;
  color: #000000;
  font-family: Roboto-Bold;
  line-height: 20px;
  font-size: 16px;
  margin-top: 35px;
  margin-bottom: 10px;
}
.cpn-des-content {
  font-size: 14px;
  color: #fff;
  opacity: 0.9;
  font-family: Roboto-Regular;
  margin-bottom: 24px;
}

.valid-msg-copied {
  font-size: 12px;
  color: red;
  text-align: center;
  margin-bottom: 5px;
  font-family: Roboto-Regular;
}

.cpn-file-logo {
  margin-left: 15px;
}
.cpn-file-logo img {
  width: 20px;
  height: 20px;
}
.coupon-prize {
  color: #fff;
  font-size: 24px;
  opacity: 0.9;
  margin-bottom: 35px;
  font-family: Roboto-Regular;
  line-height: 28px;
}
.coupon-note {
  color: rgba(255, 255, 255, 0.9);
  font-family: Roboto-Regular;
  font-size: 12px;
  text-align: center;
}
.copied {
  font-size: 11px;
  color: red;
}
.avaex {
  padding-top: 13px;
  padding-bottom: 1px;
  width: 140px;
  margin: auto;
  margin-bottom: 47px;
}
.avaex-1 {
  margin-top: -15px;
  height: 2px;
  width: auto;
  background-color: #121926;
  border-bottom: 2.5px solid rgba(255, 255, 255, 0.1);
}

.avail-unactive {
  margin-top: 0px;
  float: left;
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto-Medium;
  font-size: 18px;
  text-align: center;
  /* border-bottom:3px solid #ffffff;
 padding-bottom:2px; */
  /* text-decoration: underline; */
}

.avail-active {
  float: left;
  color: #e2b841;
  font-family: Roboto-Medium;
  font-size: 18px;
  text-align: center;
  /* text-decoration: underline; */
  border-bottom: 2.5px solid #e2b841;
  padding-bottom: 9px;
}

.expired-unactive {
  float: right;
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto-Medium;
  font-size: 18px;
  text-align: center;
  /* border-bottom:3px solid white;
    padding-bottom:2px; */
  /* text-decoration: underline; */
}

.expired-active {
  float: right;
  color: #e2b841;
  font-family: Roboto-Medium;
  font-size: 18px;
  text-align: center;
  /* text-decoration: underline; */
  border-bottom: 2.5px solid #e2b841;
  padding-bottom: 9px;
  margin-top: 0px;
}

.cpn-cd span {
  display: inline-block;
  font-family: Roboto-Regular;
  color: #ffffff;
  opacity: 0.9;
  font-size: 24px;
}

.view-coupon {
  max-width: 767px;
  margin: 0 auto;
  position: relative;
}

.close-popup {
  position: absolute;
  right: 26px;
  top: 158px;
}
.vcb-close-popup {
  position: absolute;
  right: 26px;
  top: 87px;
}

.mycouponbutton {
  margin-top: 5px;
  height: 19px;
  border: none;
  background: none;
  margin-right: 16px;
  float: right;
  color: #ffffff;
  font-size: 10px;
  background-color: #243040;
  line-height: 10px;
  padding: 5px 10px 5px;
  border-radius: 9.5px;
  right: 16px;
  z-index: 999;
}

.skeleton-title {
  
  height: 32px;
  width: 120px;
  display: block;
  background: linear-gradient(	  
      to right,
      rgba(25, 34, 51, 1),
      rgba(255, 255, 255, .1) 50%,
      rgba(25, 34, 51, 1) 100%
    ),
    #192233;
  background-repeat: repeat-y;
  background-size: 100px 10px;
  background-position: 0 0;
  animation: shine .75s infinite;	  
}

.skeleton-slide {
  border-radius: 8px;
  display: inline-block;
  background: linear-gradient(	  
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, .2) 40%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, .5);
  background-repeat: repeat-y;
  background-size: 100px 500px;
  background-position: 0 0;
  animation: shine .75s infinite;	 
}

.skeleton-slide img {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  margin-left: 4px;
}
.skeleton-loader img{
  opacity: 0.5;
}

.skeleton-loader {
  height: 72px;
  border-radius: 8px;
  width: 72px;
  display: inline-block;
  background: linear-gradient(	  
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 100px 500px;
  background-position: 0 0;
  animation: shine .75s infinite;	  
}

@keyframes shine {	
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}


.loadingDetails{
  color: #ffffff;
  font-size: 10px;
  font-family: Roboto-Medium;
}