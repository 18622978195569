*{margin: 0;padding: 0;}
				a{text-decoration: none;}
				body{font-family: 'Roboto';max-width:767px;margin:0 auto;background-color: #fff;}	
				/* The Modal (background) */
				.modal-social {
				 /* Hidden by default */
				  position: fixed; /* Stay in place */
				  z-index: 9999; /* Sit on top */
				  left: 0;
				  right: 0;
				  bottom: 0;
				  width: 100%; /* Full width */
				  height: 100%; /* Full height */
				  overflow: auto; /* Enable scroll if needed */
				  background-color: rgb(0,0,0); /* Fallback color */
				  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
				  -webkit-animation-name: fadeIn; /* Fade in the background */
				  -webkit-animation-duration: 0.4s;
				  animation-name: fadeIn;
				  animation-duration: 0.4s;
				  max-width: 767px;
				  margin: 0px auto;
                }
                
                .social-icons {
                width: 100%;clear: both;margin-bottom: 25px; float: left;
                }

                .social-icons-parent {
                padding:24px 25px 0;text-align:center;overflow:hidden;
                }

                .icons{


                    float:left;width: 20%;
                }
				
				/* Modal Content */
				.modal-content-social {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: #243040;
					width: 100%;
					-webkit-animation-name: slideIn;
					-webkit-animation-duration: 0.8s;
					animation-name: slideIn;
					animation-duration: 0.8s;
					/* height: 100px; */
					/* max-width: 767px; */
					height: 220px;
					padding: 9px;
					width: calc(100% - 18px);
					margin: 0px auto;
					border-top-left-radius: 20px;
					border-top-right-radius: 20px;
				}

				
.first{overflow: hidden;}
.second{overflow: hidden; margin-top: 26px;}
.mode{float: left; width: 33%;}
.mode1{float: left; width: 31%;}
.center-new{
    text-align: center !important;
    float: left !important;
}
.f-new{float: left !important; text-align: left; margin-left: 13px;}
.mid{text-align: center;}
.right1{float: right !important; text-align: left;}
.right{float: right !important; text-align: right;}
.right p{text-align: right !important;}
.mode img{width: 48px; height: 48px;}
.mode p{text-align: center; color: rgba(0, 0, 0, 0.87); font-size: 12px; font-family: Roboto-Regular; margin-top: 8px;}
.txt-left{text-align: left !important;}
.more-it{ margin-right: 5px;}
.share-txt{text-align: left;
    font-family: Roboto-Medium;
    font-size: 15px;
    color: #000000;
    margin-bottom: 24px;}
.dots{margin-top: 24px;}
.dots img:first-child{margin-right: 10px;}

				
				/* The Close Button */
				.close {
				  float: left;
				 margin-right: 28.5px;
				  
				}
				.heading{font-size: 16px;color: #232736;float: left;}
					
				
				.modal-header 
				{
				  padding: 25px 16.5px 20px;
				  background-color: #E0E0E0;
				  color: #232736;
				  float: left;
				  width: 100%;
				}
				
				.modal-body-social {
                    
                }
				
				
				
				/* Add Animation */
				@-webkit-keyframes slideIn {
				  from {bottom: -300px; opacity: 0} 
				  to {bottom: 0; opacity: 1}
				}
				
				@keyframes slideIn {
				  from {bottom: -300px; opacity: 0}
				  to {bottom: 0; opacity: 1}
				}
				
				@-webkit-keyframes fadeIn {
				  from {opacity: 0} 
				  to {opacity: 1}
				}
				
				@keyframes fadeIn {
				  from {opacity: 0} 
				  to {opacity: 1}
				}

				.icons {
					float: left;
					width: 20%;
					display: inline;
					float: initial;
				}
				.fWYWWZ{
					width: 120px;
					text-align: center;
					padding: 0.5em 0em !important;
				}
				.cgeQjm{
					display: block !important;
				}
				.ksIwnZ{
					width: 120px;
					text-align: center;
					padding: 0.5em 0em !important;
				}
				.eqeAgc{
					width: 120px;
					text-align: center;
					padding: 0.5em 0em !important;

				}
				.bwSWdB{
					width: 120px;
					text-align: center;
					padding: 0.5em 0em !important;
				}
				.lgCdjb{
					width: 120px;
					text-align: center;
					padding: 0.5em 0em !important;
				}