
*{margin: 0;padding: 0;}
@font-face{font-family: Lato-Semibold; src: url(Lato-Semibold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Black; src: url(Lato-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Medium; src: url(Lato-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Bold; src: url(Lato-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Light; src: url(Lato-Light.ttf);font-weight: normal;font-style: normal;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}

.winnings-divs{margin-top: 0px;}
.play-o{padding:23px 0px 23px 19px;background-color:#243040;
    margin-top: 4px;overflow: hidden;margin-bottom: 50px;height: 97px;}

.play-h{color:#ffffff;font-family: Roboto-Regular;  font-size: 14px;text-align:left;margin-bottom: 10px;}

.play-hh img{width:21px;height:21px;
}

.imageplaywin{
    margin-top: 12px;
    margin-left: 13px;
}



.play-oo{padding:22px 16px 22px 19px;background-color:#e60000;
    margin-top: 30px;overflow: hidden;margin:0px 16px 0px 16px;margin-bottom: 50px;border-radius:8px}

.play-oo-l{float: left;}
.play-hh{color:#ffffff;font-family: Roboto-Regular;font-size: 14px;text-align:left;width:100%;float: left;}
.play-hhh{overflow: hidden;}
.play-oo img{width:40px;height:40px;float:left;margin-right: 10px;float:left;    margin-top: 5px;}
.play-cc{color:#ffffff;font-family: Roboto-Medium;font-size: 24px;float: left;}

.play-oo-r{float: right;}
.play-oo-r img{width:40px;height:40px;    margin-top: 5px;}



.coin-his{color:#cccccc;font-family: Roboto-Regular;font-size: 24px;margin-left: 16px;margin-bottom: 28px;text-align: left;}
.choose-play{border-radius:29px;background-color:rgb(216, 216, 216,0.10);margin-right:16px;
margin-left:16px;margin-bottom:38px;padding:6px 8px 9px 8px;}

.choose-catg{border-radius:29px;background-color:#cccccc;width:33.5%;padding:11px 0px 11px 0px;}
.choose-catg p{color:#000000;font-family: Roboto-Medium !important;font-size: 14px;}
.choose-catg-b{border-radius:29px;width:33.5%;padding:11px 0px 11px 0px; 
    color: #666666; font-family: Roboto-Regular;}
.f{float:left}
.s{margin:0px auto}
.t{float:right;margin-top: -41px;}

.dates-div{padding-bottom: 0px;}
.dte-div{padding-left: 18px;margin-bottom: 19px;}
.show-d{color:#666666;font-family: Roboto-Regular;font-size: 12px;text-align: left;margin-bottom: 0px;}

.winnings-coins{float:right;margin-right: 16px;}
.winnings-coins p{color:#01b922;font-family: Roboto-Regular;font-size: 14px;margin-top: 0px; margin-right: 7px;}
.coin-g-aaa{font-family: Roboto-Regular;color:#ffffff;font-size: 14px;text-align: left;margin-top: -18px;}
.games-www{padding:0px 0px 0px 0px;padding-top: 16px;}
.games-www img{width:21px;height:21px;float: left;margin-right: 15px;}

.red-it{color:#ff3316 !important}

.red{color:#ff3316 !important}
.green{color:#01b922 !important}

.norecord{color: #3c3c3c ; font-size: 24px; font-family: Roboto-Regular; padding: 0px 25px;}                                                                  