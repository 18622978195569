*{margin: 0;padding: 0;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}


.container-prize{max-width:767px;margin:0 auto;
    background-color:#121926; height: 100%;overflow: hidden;}
.overflow-noti{overflow: auto !important;}    
.prize-table{margin-top: 60px;}
.content-notification{margin-top: 69px;}
.today-txt{ float:left;font-family: Roboto-Medium; font-size: 20px; 
    color: #000000; padding-left:16px; margin-bottom: 25px;}

 .no-notification{
    font-family: Roboto-Regular; margin-top: 300px; font-size: 20px; color:rgba(255,255,255,0.50);
 }   
.n-ticket{
    border-radius: 8px;
    margin: 0px 16px;
    overflow: hidden;
    clear: both;
    padding: 17px 16px;
    background-color: #243040;
    font-family: Roboto-Regular;
}    

.n-ticket-unread{
    background-color: rgba(255,255,255,0.4) !important;
}

.n-time{color: rgba(255,255,255,0.50);
    font-size: 14px;
    float: left;
    font-family: Roboto-Regular;
    margin-top: 25px;
}
.n-head{font-size: 18px;
    color: #fff;
    float: left;
    font-family: Roboto-Medium;}
.n-detail{
    font-family: 'Roboto-Regular';
    overflow: hidden;
    padding-top: 13px;
    clear: both;
    text-align: left;
    color: rgb(255, 255, 255,0.75);
    font-size: 16px;
}
.mar-top{margin-top: 18px; }
.mar-btm{margin-bottom: 30px;}
.n-detail img{width: 100%; margin-top: 20px;}

.notification-header{
    padding-left: 15px !important;
}