*{margin: 0;padding: 0;}
@font-face{font-family: Lato-Semibold; src: url(Lato-Semibold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Black; src: url(Lato-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Medium; src: url(Lato-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Bold; src: url(Lato-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Light; src: url(Lato-Light.ttf);font-weight: normal;font-style: normal;}

@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}

.datepicker.default .datepicker-navbar-btn {
    color: #e2b841 !important;
}



a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}

.container-ll{max-width:767px;margin:0 auto;height: 100%;padding-top: 59px;
    background-color:#121926;;overflow: hidden;}

.leader-header{padding:14px 0px 13px 0px;text-align: left;overflow: hidden;position: fixed;
               top:0;max-width: 767px;width:100%}
.leader-header img{width:18px;height:18px;margin-right: 21px;float: left;margin-top: 8px;}
.leader-header p{font-family: Roboto-Medium;font-size: 24px;color:#cccccc;float: left;}

.leader-g{padding:17px 0px 21px 22px;background-color: rgb(255, 255, 255,0.10);
              margin-top: 30px;overflow: hidden;margin:0px 16px 0px 16px;margin-bottom: 30px;
              background-image: linear-gradient(to left, #3186f0 , #6143cf );border-radius:8px}
.leader-g img{width:60px;height:60px;margin-right: 15px;float: left;}

.leader-gr{float: left;}
.leader-grp{font-family: Roboto-Regular;font-size: 16px;color:#ffffff;margin-bottom: 8px;text-align: left;}

.rank-l p{font-family:Roboto-Regular;font-size:16px;color:#ffffff;float: left;}
.rank-l p:first-child{border-right:1px solid #ffffff;padding-right: 6px;}
.rank-l p:last-child{padding-left: 6px;}

.rank-user{overflow:hidden;padding:7px 16px 7px 16px;background-color: rgb(216, 216, 216,0.10);}
.rank-r{color:#666666;font-family: Roboto-Regular;font-size: 12px;float: left;margin-right: 60px;}
.user-r{color:#666666;font-family: Roboto-Regular;font-size: 12px;float: left;}
.score-r{color:#666666;font-family: Roboto-Regular;font-size: 12px;float: right;}
 
.ru-c{padding:20px 0px 0px 16px;}
.ru-cr{color:#666666;font-family:Roboto-Regular;font-size:16px;float:left;margin-right: 27px;margin-top:11px}
.ru-cu{padding-bottom: 32px;border-bottom:1px solid #d8d8d8;overflow: hidden;}
.leaderBottom{width:40px;height:40px;float:left;margin-right:10px}
.ru-cu p:first-child{color:#cccccc;font-family:Roboto-Regular;font-size:16px;float: left;margin-top:11px}
.ru-cs{color:#faaf40;font-family:Roboto-Regular;font-size:16px;float: right;margin-right: 16px;margin-top:11px}

.ru-table{padding-bottom: 30px;}

.leader-new{padding-top: 30px;}
.leader-no{margin-top: 15px !important;}

.copy-every{border-bottom:1px solid rgba(255,255,255,0.08);}

.container-leaderboard-e{max-width:767px;margin:0px auto;padding:0px;background-color: #121926 ;
    padding-top: 0px;  overflow:auto;height:100% !important}

