*{margin: 0;padding: 0;}
@font-face{font-family: Lato-Semibold; src: url(Lato-Semibold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Black; src: url(Lato-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Medium; src: url(Lato-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Bold; src: url(Lato-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Lato-Light; src: url(Lato-Light.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Avenir-Medium; src: url(Avenir-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Medium; src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular; src: url( Roboto-Regular.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Bold; src: url( Roboto-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Avenir-Heavy; src: url(Avenir-Heavy.ttf);font-weight: normal;font-style: normal;}

::-webkit-input-placeholder { /* Chrome */ color: rgba(255, 255, 255, 0.50); font-family: Avenir-Heavy; }
:-ms-input-placeholder { /* IE 10+ */ color: rgba(255, 255, 255, 0.50); font-family: Avenir-Heavy; }
::-moz-placeholder { /* Firefox 19+ */ color: rgba(255, 255, 255, 0.50); font-family: Avenir-Heavy; }
:-moz-placeholder { /* Firefox 4 - 18 */ color: rgba(255, 255, 255, 0.50); font-family: Avenir-Heavy; }

a {text-decoration: none;border: none;color: #000;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;}

#root{height: 100%;}
.App{height:100%}
html{height: 100%;}
body{height: 100%;}
.container-login{height:100%; max-width:767px;margin:0 auto; 
    background-color:#121926;padding:15px 16px 0px 16px;}

    .error-login p{float: center; margin-top:241px; margin-bottom:3px;color: rgb(255,51,22); font-family: Roboto-Regular; font-size: 30px;}
    .error-login-desc p{float: center;  margin-bottom:3px;color: rgb(255,51,22); font-family: Roboto-Regular; font-size: 14px;}

.error-txt-red{float: center; margin-top:9px; margin-bottom:9px;color: rgb(255,51,22); font-family: Roboto-Regular; font-size: 14px;}
.error-txt-otp{
    margin-bottom: 15px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 9px;
    color: rgb(255,51,22);
    font-family: Roboto-Regular;
    font-size: 12px;
}
.disable-gry{background-color: #e2b841;
    opacity: 0.50;
    color: rgba(0,0,0,0.50) !important;}
.disable-gry-txt{color:rgb (102,102,102) !important;}
/* .enter-o-gry{ border-bottom: 0.5px solid rgb(255,51,22) !important;} */
.enter-o-gry-ph{ border-bottom: 0.5px solid rgb(102,102,102) !important;}
.enter-grey{border-bottom: 0.5px solid #666666 !important;}

.msisdn-p{    color: #ffffff;
    font-size: 18px;
    font-family: Roboto-Bold;
    text-align: left;
    margin-bottom: 24px;
    line-height: 46px;}

.msisdn-sub-p{width:226px;color:rgba(255,255,255,0.50);font-family: Roboto-Regular;
    font-size: 14px;margin-bottom: 10px;text-align: left;}
.e-mar-tp{margin-top:0px !important;}    
.e-mar-tp-7{margin-top: 7px !important;}
.e-p-it {
    padding-top: 22px !important;
    padding-bottom: 23px !important;
}
.e-number{color: #666666 !important;
    font-size: 16px !important;
}
.sd-btn{    padding: 14.5px 0px;
    font-size: 16px;
    background-color: #e2b841;
    border-radius: 4px;
    font-family: Roboto-Bold;
    border: none;
    width: calc(100% - 32px);
    position: fixed;
    left: 16px;
    right: 0;
    bottom: 15px;
}
.pc-list{overflow: hidden;
    padding-top: 14px;}
.pl-left{    margin-right: 12px;
    padding-left: 16px;
    float: left;
    padding-top: 5px;}
.pl-right{padding-bottom: 18px;
    padding-right: 16px;
    width: calc(100% - 54px);
    float: right;
    border-bottom: 1px solid rgba(255,255,255,0.10);}
.pl-right p{      margin-top: 8px;
    font-family: Roboto-Regular;
    font-size: 14px;
    float: left;
    color: #ffffff;
}
.wap-bottom{text-align: left;
    padding: 0px 16px;
    margin-top: 10%;
    padding-bottom: 9px;
}
.wap-bottom p{font-family: Roboto-Regular;
    font-size: 12px;
    color: rgba(255, 255, 255,0.50);} 
.wap-bottom img{
    height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}    
.pl-left img{width: 24px; height: 24px;}
.pl-right img{width: 6px; height: 13px; float: right;margin-top: 11px;}
.profile-head{overflow: hidden;background-color: #243040; padding: 8px 16px;clear: both; margin-top: 71px; height: 70px;}
.ph-1{float: left;}
.ph-1 img{float: left;width: 55px; height: 55px;border-radius: 50%;object-fit: cover;}
.ph-2{float: right;}
.ph-2 img{width: 6px; height: 13px;    margin-top: 22px;}
.ph-1-p{float: right;margin-left: 14px;    margin-top: 7Fpx;}
.ph-1-p p:first-child{font-family: Roboto-Medium; font-size: 16px; margin-bottom: 4px; margin-top: 7px;
    color: #ffffff;text-align: left;}
.ph-1-p :nth-child(2){font-size: 12px; font-family: Roboto-Regular; color: rgba(255,255,255,0.65);text-align: left;}
.mn-right img{margin-top: 4px;}
.mn-pd{padding-top: 3px;}

.menu-new{    overflow: hidden;
    background-color: #243040;
    padding: 18px 16px;}
.mn-left{float: left;width: calc(100% - 28px);}
.mn-left span:first-child{float: left;font-size: 16px; color: #ffffff; font-family: Roboto-Medium;}
.mn-left span:nth-child(2){float: right;
    font-size: 14px;
    color: rgba(255,255,255,0.65);
    font-family: Roboto-Regular;}
.mn-right{float: right;}
.ve-p{font-family: Roboto-Regular;
    text-align: left;
    font-size: 14px;
    color: rgba(255,255,255,0.50);}
.re-oo{text-align: right; font-family: Roboto-Regular; margin-top: -13px;
    color: rgba(255,255,255,0.50); font-size: 12px;}    
.pass-red{position: relative;
    text-align: left;
    color: #e60000;
    font-family: Roboto-Regular;
    font-size: 12px;
    overflow: hidden;
    clear: both;
    top: -60px;
}
.n-10{font-size: 16px; font-family: Roboto-Regular;text-align: left;
     color: rgba(255,255,255,0.50);margin-top: -55px;    margin-bottom: 65px;}
.msisdn-sub-p span{font-family: Roboto-Medium !important;}    
.pass-btm{font-size: 12px; font-family: Roboto-Regular; color: rgba(255,255,255,0.50);}
.enter-mm{overflow: hidden; border-bottom: 1px solid rgb(255,255,255); padding-bottom: 5px;}
.enter-mm-gry{overflow: hidden;  border-bottom: 0.5px solid #666666; padding-bottom: 5px;}
.np-text{color: rgba(255, 255, 255,0.50); text-align: left; margin-bottom: 12px;
    font-family: Roboto-Regular; font-size: 14px;margin-top: -38px;}
.np-text-hide{
    color: rgba(255, 255, 255,0.50); text-align: left; margin-bottom: 12px;
    font-family: Roboto-Regular; font-size: 14px;margin-top: -38px; visibility: hidden;
}

.enter-mm span{    float: left;
    padding-bottom: 0px;
    width: 42px;
    text-align: left;
    color:rgb(250,175,64);
    font-family: Roboto-Bold;
    font-size: 18px;}
.enter-mm-gry span{    float: left;
        padding-bottom: 0px;
        color:rgba(255,255,255,0.50);
        font-family: Roboto-Medium;
        width: 42px;
        text-align: left;
        font-size: 18px;}
.loginn{text-align: left;
    font-size: 16px;
    font-family: Roboto-Regular;
    color: rgba(255,255,255,0.50);}        
.enter-m{border:none;color: rgb(250,175,64);font-family: Roboto-Bold;
    font-size:18px;background: none;float: left;width: calc(100% - 60px);}
.enter-m-gry{border:none;color: #cccccc;font-family: Roboto-Bold;
        font-size:18px;background: none;float: left;width: calc(100% - 60px);}    
.button-submit{margin-top: 50px;margin-bottom:26px}
.submit-login{ background-color: #e2b841;
    border: none;
    padding: 14px 0px 15px 0px;
    width: 100%;
    font-size: 16px;
    font-family: Roboto-Bold;
    color: #121926;
    border-radius: 4px;}
.inactive{background-color:#666666 ;}
.set-reset{text-decoration: underline;
    margin-top: 15px;
    text-align: right;
    font-size: 12px;
    color: #29bdff;
    font-family: Avenir-Medium;}
.enter-p{    border: none;
    color: rgb(255,255,255);
    font-family: Avenir-Heavy;
    font-size: 20px;
    background: none;
    float: left;
    width: calc(100% - 16px);}    
.enter-otp{overflow: hidden;}

.enter-o-active{
    border-bottom: 0.5px solid #e2b841 !important;
   }


.enter-o{float: left;
    text-align:center;
    background:none;
    border:none;
    border-bottom: 0.5px solid  rgba(255,255,255,0.59);
    padding-bottom: 0px;
    color:  rgb(255,255,255);
    font-family: Roboto-Bold;
    font-size: 20px;margin-right: 2.5%;width: 40px;
     width:24px;
    border-radius: 0px;}
.enter-o:last-child{margin-right: 0px;}
.seconds-c{text-align: center;}
.send-otp{font-family: Roboto-Regular;font-size: 14px;color: #666666;float: left;margin-right: 6px;}
.seconds{font-family: Roboto-Regular;font-size: 14px;color:#cccccc;float: left;}
.otp-div{margin:0px auto;width:145px;overflow: hidden;margin-bottom: 17px;}

.change{font-family: Roboto-Regular;color:#666666;font-size: 14px;text-decoration: underline;text-align: center;}




.container-splash{max-width:767px;margin:0 auto;height: 100%;
    background-color:#121926; 
    background: url(../images/splash.png) no-repeat 0 0; background-size: 100% 100%;
    padding:0px 0px 0px 0px;overflow: hidden;}

.splash-banner{width: 122px;height: 110px;position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);}
.splash-banner img{width: 100%;height: 100%;}

.load-b{position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%);}
.load-img{width:32px;height: 32px;margin:0px auto;margin-bottom: 8px;}
.load-img{width:100%;height:100%;}
.load-b p{font-family: Roboto-Regular;color:#666666;font-size: 12px;text-align: center;}

.loader-img{width:32px; height: 32px;}





 .login-border{border-bottom: 0.5px solid #d8d8d8;} 
.mrg-extra{margin-top:76px;}

.gameview{
    /* margin-top: 56px; */
    -webkit-transform: scale(0.92);
    position: absolute !important;
    left: 0;
    right: 0;
    top: 25px !important;
    bottom: 0;
    width: 100%;
    border: none;
}
.header-leaderboard {
    height: 53px;
}
.container-question{max-width:767px;margin:0 auto;
    background-color:#121926;;padding:0px;overflow: auto; height: 100%;}
.container-battle{max-width:767px;margin:0 auto;
    background-color:#121926;;padding:0px;overflow: auto; height: 100%;}    
.header-ques{overflow: hidden;
    margin-top: 38px;
    padding-bottom: 30px;
}    
.header-battle{overflow: hidden;
    padding: 16px 16px 10px 19px;border-bottom: 1px solid rgba(0,0,0,0.15);}
.hb-l{float: left;}
.hb-c{float: left; font-family: Roboto-Medium; font-size: 20px; color: #000000; margin-top: -5px;}
.hb-r{    padding: 4.6px 4.4px;
    margin-top: -5px;
    float: right;
    background-color: #e60000;
    border-radius: 14.8px;}
.hb-r span{font-family: Roboto-Regular; color: #ffffff; font-size: 16.9px; position: relative;
    margin-left: 4.4px; top: -1px;}    
.hb-r img{width: 21px; height: 21px; float: left;}
.header-ques p{margin-left: 28px;
    font-family: Roboto-Regular;
    font-size: 16px;
    text-align: center;
    width: calc(100% - 59px);
    float: left;}
.header-ques img{width: 15px;
    height: 15px;
    float: right;
    margin-right: 16px;}    
.opponent{padding: 0px 61px;
    overflow: hidden;
    margin: 0px auto;
    text-align: center;}
.opp-one{border-radius: 50%;
    float: left;}
.opp-one img{width: 46px; height: 46px;}    
.opp-two{float: left;
    text-align: center;
    width: calc(100% - 126px);
    border-radius: 50%;}
.opp-two img{width: 46px; height: 46px;}    
.opp-three{border-radius: 50%;
    float: right;}    
.opp-three img{width: 46px; height: 46px;}  
.opp-one-p{font-family: Roboto-Regular; font-size: 12px; color: #000000;}  
.opp-two-p{font-family: Roboto-Regular; font-size: 12px; color: #000000;}  
.opp-three-p{font-family: Roboto-Regular; font-size: 12px; color: #000000;}  
.opp-one p:nth-child(3n){font-family: Roboto-Medium; font-size: 16px; color: #000000;}
.opp-two p:nth-child(3n){font-family: Roboto-Medium; font-size: 16px; color: #000000;}
.opp-three p:nth-child(3n){font-family: Roboto-Medium; font-size: 16px; color: #000000;}
.ques-div{margin: 20px 16px 23px 16px; box-shadow: 0px 0px 40px 17px #DCDCDC;
    padding: 14px 39px 2px 39px; border: 1px solid #ffffff;}
.q-num{font-family: Roboto-Regular; font-size: 16px; color: rgba(0,0,0,0.50);margin-bottom: 22px;}    
.timer{overflow: hidden;}
.q-text{font-family: Roboto-Regular; color: #000000; font-size: 20px; margin-bottom: 11px;
    margin-top: 16.7px;}
.status-correct{ margin-bottom: 16px;}
.status-wrong{margin-bottom: 16px;}
.option div{width: 99%; border: 1px solid rgba(0,0,0,0.25); border-radius: 4px; font-size: 18px; 
    padding: 12px 0px 12px 0px; margin-bottom: 16px; color: rgba(0,0,0,0.50); font-family: Roboto-Regular;}
.option-g{font-family: Roboto-Regular !important; color: #000000 !important; 
    font-size: 18px !important; background-color: #15a303 !important; border: 1px solid #15a303 !important;}
.option-r{font-family: Roboto-Regular !important; color: #000000 !important; 
    font-size: 18px !important; background-color: #e60000 !important; border: 1px solid #e60000 !important;}
.countdown{
    text-align: center;
    font-family: Roboto-Medium;
    font-size: 26px;
    width: 45.2px;
    border-radius: 50%;
    height: 45.2px;
    margin: 0px auto;
    background-color: #40ac18;
}
.round-div{margin-top: 40px;}
.badge-img{width: 142px; height: 135px;}   
.round-start-txt{font-family: Roboto-Regular; color: #1867ec; font-size: 18px; 
    margin-top: 20px; margin-bottom: 39px;} 
.round-txt{font-family: Roboto-Regular; font-size: 20px; color: #000000;}
.round-num{font-family: Roboto-Regular; font-size: 24px; color: #000000; margin-top: 14px;}  
.grey-circle{border-radius: 50%; height: 40px; width: 40px; margin: 0px auto;
    background-color: rgba(0,0,0,0.06);}  
.sr-img{width: 100px !important; height: 100px !important;}    
.s-o-img{    top: -78px;
    width: 46px !important;
    height: 46px !important;
    position: relative;
}

.opp{
    text-align: center;
    border-radius: 50%;}
.opp img{width: 46px; height: 46px;}  
.search-div{padding: 0px 34px; overflow: hidden; margin-top: 18px;}  
.opp-lft{
    float: left;
    text-align: center;
    width: 100px;
    border-radius: 50%;
}
.opp-ryt{
    float: right;
    text-align: center;
    width: 100px;
    border-radius: 50%;
}   
.searching-txt{margin-top: 61px; color: #1867ec; font-family: Roboto-Regular; font-size: 18px;} 
.back-img{height: 18px; width: 18px; margin-right: 22px;}
.content-battle{padding: 20px 16px 49px 16px;}
.players-div{background-color: #ffddc4; padding: 19px 26px 0px 26px; 
    margin-top: 19px; border-top-left-radius: 8px; border-top-right-radius: 8px;}
.players-img{width: 100%; height: 103px;}
.play-data{background-color: #ffddc4; padding: 14px 50px 19px 50px; font-size: 14px;
    margin-top: 2px; font-family: Roboto-Medium; color: #000000;
    border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;
}
.p-n-btn{border-radius: 16.5px; background-color: #e60000; color: #ffffff; border: none;
    padding: 9px 18px 8px 19px; font-family: Roboto-Medium; font-size: 16px; margin-top: 30px;}
.b-note{font-family: Roboto-Regular; font-size: 12px; color: rgba(0,0,0,0.50); 
    margin-top: 47px; padding: 0px 52px;}    
.header-b-pc{border-bottom: 1px solid rgba(255,255,255,0.15);
    overflow: hidden;
    margin-top: 34px;
    padding-bottom: 14px !important;
    padding: 0px 16px;
}    
.header-won{
    overflow: hidden;
    margin-top: 39px;
    padding-bottom: 17px !important;
    padding: 0px 16px;
} 
.header-won img{float: right;}   
.header-b-pc p{float: left; font-size: 20px; font-family: Roboto-Medium; color: #ffffff;}
.header-b-pc img{float: right;}    
.content-b-pc{
    padding: 34px 0px 0px 16px;
} 
.content-won{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-top: 64px !important;
    margin: 0px 16px;
    padding: 0px 16px 0px 16px;
    border: 1px solid white;
    box-shadow: 0px 0px 40px 17px #d8d8d8;
    overflow: hidden;
} 
.pck-txt{font-family: Roboto-Regular; font-size: 14px; text-align: left;
    color: #ffffff; margin-bottom: 34px; margin-right: 16px !important;}
.b-pc-table{width: 100%; }
.b-pc-table td{
    border-bottom: 1px solid rgba(255,255,255,0.15);
}
.td-one{/*float: left;*/ padding: 20px 0px; border: none !important;}
.td-one img{margin-right: 10px;
    width: 24px;
    height: 24px;}
.td-two{   
    float: left;
    padding-bottom: 25px !important;
    font-size: 18px;
    font-family: Roboto-Regular;
    color: #ffffff;
    padding: 20px 0px;
    width: 100%;
    text-align: left;
}
.b-top{border-top: 1px solid rgba(255,255,255,0.15) !important;}    
.td-three div{
    border-radius: 4px;
    background-color: #e2b841;
    color: #121926;
    font-family: Roboto-Medium;
    font-size: 18px;
    word-spacing: -3px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.tdd-blue{}
.tdd td div{
    background-color: #121926 !important;
    color: #e2b841 !important;
    font-size: 14px !important;
    border: 1px solid #e2b841 !important;
    padding: 3px 10px;
    width: fit-content;
    float: right;
}
.td-three{padding-right: 16px !important; padding: 20px 0px; position: relative;left: -2px !important;}
.ww-lft{border-radius: 50%; width: 45.3px; height: 45.3px; float: left;}
.ww-lft img{width: 100%; height: 100%;}
.opp-selected{border: 2.4px solid #e60000; border-radius: 50%;}
.w-c p:first-child{font-family: Roboto-Regular; font-size: 16px; color: #000000; text-align: left;}
.w-c p:nth-child(2n){font-family: Roboto-Medium; font-size: 18px; color: #e60000; text-align: left;}
.w-r p:first-child{font-family: Roboto-Medium; font-size: 16px; color: #000000; text-align: right;}
.w-r p:nth-child(2n){font-family: Roboto-Regular; font-size: 12px; color: #000000; 
    text-align: right; margin-top: 7px;}
.w-c{border-bottom: 1px solid #d8d8d8; width: calc(100% - 82px);float: left; 
    margin-left: 16.7px; padding-bottom: 21px;}
.w-r{border-bottom: 1px solid #d8d8d8; float: right; padding-bottom: 22px;}
.ww-ryt{overflow: hidden;}
.cw-ticket{margin-top: 17px;}
.cw-ticket:last-child .ww-ryt .w-c{border: none !important;}
.cw-ticket:last-child .ww-ryt .w-r{border: none !important;}
.coupon{border-bottom-left-radius: 8px;
    margin: 0px 16px;
    background-color: rgba(21,163,3,0.25);
    border-bottom-right-radius: 8px;
    padding:17px 0px 16px 0px;
}
.lose{border-bottom-left-radius: 8px;
    margin: 0px 16px;
    background-color:#ffddc4;
    border-bottom-right-radius: 8px;
    padding:24px 0px 23px 0px;
}
.cc-t{font-family: Roboto-Regular; color: #000000; font-size: 14px; margin-bottom: 7px;}
.cc-t-r{font-family: Roboto-Regular; color: #000000; font-size: 14px; margin-bottom: 7px;padding: 0 65px;}
.cc-code{font-size: 21px;font-family: Roboto-Regular; color: #000000;}
.cc-code img{height: 15px; width: 15px; margin-left: 8px;}
.re-btn{background-color: #e60000; border:1px solid #e60000; color: #ffffff; font-size: 16px; 
    font-family: Roboto-Medium; padding:9px 19px; border-radius: 16.5px; margin-top: 38px;}
.footer-note{margin-top: 47px;
    padding: 0px 58px;
    font-size: 12px;
    font-family: Roboto-Regular;
    color: rgba(0,0,0,0.5);}    
.t-img{text-align: center;}
.t-img img{width: 32px; height: 32px;}
.s-login{font-size: 12px; font-family: Roboto-Regular; color: rgba(255,255,255,0.65);}
.s-inactive{margin-top: 12px;
    padding: 0px 62px;
    font-size: 18px;
    font-family: Roboto-Regular;
    color: #ffffff;
    margin-bottom: 19px;}
.lo-btn{    margin-left: 16px;
    margin-top: 24px;
    margin-right: 16px;
    width: calc(100% - 32px);
    border-radius: 4px;
    background-color: #e2b841;
    border: none;
    padding: 15px 0px;
    font-size: 16px;
    font-family: Roboto-Bold;
    color: #121926;}
.container-session{max-width:767px;margin:0 auto;height: 100%;
    background-color:#121926; 
    padding:60% 0px 0px 0px;overflow: hidden;}
.ee-img{width: 15px;
    float: right;
    position: absolute;
    top: 25px;
    right: 16px;
    height: 15px;}    
    


    .address-span{
        width: 150px;
        text-align: right;
    }

    .pwa-custom{
        padding: 10px 15px;
        width: fit-content;
        position: fixed;
        bottom: 80px;
        right: 0px;
        left: 0px;
        margin: 0px auto;
        background-color: #ffffff;
        z-index: 999;
    }

    .close{
        float: right  !important;
        margin-right: 0px !important;
        /* float: left; */
        /* margin-right: 28.5px; */
        margin-left: 10px !important;
    }


    .add-to-home{
        background-color: transparent;
        border: none;
        color: #000000;
        font-size: 16px;
    }
    .winner-box {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    .winner .group {
        width: 100%;
        height: 128px;
    }
    .winner .winner-box .name {
        font-size: 16px;
        color: #fff;
        margin-bottom: 6px;
        font-family: Roboto-Medium !important;

    }
    
    .winner .winner-box .dec-new {
        
        font-size: 14px;
        color: #ffffff65;
        font-family: Roboto-Regular !important;
    }
   
    .winner .winner-box .dec {
        
        font-size: 12px;
        color: #ffffff65;
        font-family: Roboto-Regular !important;
    }
    .winner .winner-box .link {
        font-size: 14px;
        color: #e2b841;
        margin-bottom: 14px;
        font-family: Roboto-Regular !important;
    }

    .paycoins{
        font-family: Roboto-Regular !important;
    }

    .winner .winner-box .r1 {
        margin-top: -4px;
        height: 106px;
        padding: 20px 0 10px 0;
        background-color:#243040;
        margin-bottom: 2px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        /* border-radius: 12px; */
    }
    .winner .winner-box .r2 {
        height: 106px;
        padding: 20px 0 10px 0;
        background-color:#243040;
        margin-bottom: 2px;
    }
    .winner .winner-box .r3 {
        height: 106px;
        padding: 20px 0 10px 0;
        background-color:#243040;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    .win-close {
        height: 24px !important;
        width: 24px !important;
        margin-right: 21px;
        margin-top: 0% !important;
    }
    .t-head {
        background-color: #243040;
    }
    .winners tr,table {
        width: 100%;
    }
    .winners th {
        background-color: #243040;
        color: #ffffff65;
        font-family: Roboto-Regular !important;
        font-size: 12px;
        height: 30px;
        width: 33%;
        text-align: center;
        border: 2px solid #243040;
        
    }
    .winners th:first-child {
        text-align: left;
        padding-left: 18px;
        
    }
    .winners th:last-child {
        text-align: end;
        padding-right: 18px;
    }
    
    .winners td {
        color: #ffffff;
        font-family: Roboto-Regular !important;
        font-size: 14px;
        height: 45px;
        width: 33%;
        text-align: center; 
    }
    .winners .active {
        color: #e2b841;
    }
    
    .winners td:first-child {
        text-align: left;
        padding-left: 20px;
        
    }
    .winners td:last-child {
        text-align: end;
        padding-right: 20px;
    }

    .socialLoginButton{
        width: 200px;
        margin-top: 2px;
    }


    @media (max-height: 560px) {
        .wap-bottom{
            position: static;
            bottom: 20px;
            margin-top: 20px;
        }
    }