
.loaderContainer{
    max-width: 100px;
        
    margin: auto;
    
    position: relative;
    
    overflow: auto;
    padding: 24px 24px 24px 24px;
    }
    
    
    .loaderContainer{text-align: center;}
    
    .loaderContainer .modal-heading div svg g g{stroke-width: 2px;}
    .loaderContainer .modal-heading div svg g g circle{stroke-opacity: .4;}

    .loader_gif{
        -webkit-animation:spin 2s linear infinite;
        -moz-animation:spin 2s linear infinite;
        animation:spin 2s linear infinite;
    }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.loader_txt{color:#666666;font-size: 12px;font-family: Roboto-Regular;}